import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8f04537e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _632e98b3 = () => interopDefault(import('../pages/Editorial/About.vue' /* webpackChunkName: "" */))
const _a3ec10f8 = () => interopDefault(import('../pages/Blog.vue' /* webpackChunkName: "" */))
const _78974e44 = () => interopDefault(import('../pages/BlogPost.vue' /* webpackChunkName: "pages/BlogPost" */))
const _544757fc = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "" */))
const _0b2841e0 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "pages/Category" */))
const _48ef6e28 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _26e24f74 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _74dcbd02 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _15bc54f6 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _42a0bda8 = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _7855b4db = () => interopDefault(import('../pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _35347821 = () => interopDefault(import('../pages/CmsPreview.vue' /* webpackChunkName: "" */))
const _b1da17a4 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "" */))
const _c90f5248 = () => interopDefault(import('../pages/Editorial/Contract.vue' /* webpackChunkName: "" */))
const _deef8500 = () => interopDefault(import('../pages/Editorial/Lavorazione.vue' /* webpackChunkName: "" */))
const _3dc44706 = () => interopDefault(import('../pages/ListingPreview.vue' /* webpackChunkName: "" */))
const _10189cef = () => interopDefault(import('../pages/MyAccount.vue' /* webpackChunkName: "pages/MyAccount" */))
const _6f2f1c30 = () => interopDefault(import('../pages/MyAccount/AddressesDetails.vue' /* webpackChunkName: "pages/MyAccount/AddressesDetails" */))
const _03b110f5 = () => interopDefault(import('../pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "pages/MyAccount/MyNewsletter" */))
const _a43397c6 = () => interopDefault(import('../pages/MyAccount/MyProfile.vue' /* webpackChunkName: "pages/MyAccount/MyProfile" */))
const _42139b54 = () => interopDefault(import('../pages/MyAccount/OrderHistory.vue' /* webpackChunkName: "pages/MyAccount/OrderHistory" */))
const _08b2ebf1 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _680ee06a = () => interopDefault(import('../pages/PayloadPage.vue' /* webpackChunkName: "pages/PayloadPage" */))
const _71483cc6 = () => interopDefault(import('../pages/Playground.vue' /* webpackChunkName: "pages/Playground" */))
const _4bba9d7d = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _693350d0 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _34cba952 = () => interopDefault(import('../pages/Route.vue' /* webpackChunkName: "pages/Route" */))
const _6f8b500a = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "" */))
const _3fbd01b2 = () => interopDefault(import('../pages/Wishlist.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ae-de",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ae-de"
  }, {
    path: "/ae-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ae-en"
  }, {
    path: "/ae-es",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ae-es"
  }, {
    path: "/ae-fr",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ae-fr"
  }, {
    path: "/ae-it",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ae-it"
  }, {
    path: "/ch-de",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ch-de"
  }, {
    path: "/ch-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ch-en"
  }, {
    path: "/ch-es",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ch-es"
  }, {
    path: "/ch-fr",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ch-fr"
  }, {
    path: "/ch-it",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___ch-it"
  }, {
    path: "/eu-de",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___eu-de"
  }, {
    path: "/eu-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___eu-en"
  }, {
    path: "/eu-es",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___eu-es"
  }, {
    path: "/eu-fr",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___eu-fr"
  }, {
    path: "/eu-it",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___eu-it"
  }, {
    path: "/fe-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___fe-en"
  }, {
    path: "/it-it",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___it-it"
  }, {
    path: "/jp-de",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___jp-de"
  }, {
    path: "/jp-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___jp-en"
  }, {
    path: "/jp-es",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___jp-es"
  }, {
    path: "/jp-fr",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___jp-fr"
  }, {
    path: "/jp-it",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___jp-it"
  }, {
    path: "/me-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___me-en"
  }, {
    path: "/row-de",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___row-de"
  }, {
    path: "/row-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___row-en"
  }, {
    path: "/row-es",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___row-es"
  }, {
    path: "/row-fr",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___row-fr"
  }, {
    path: "/row-it",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___row-it"
  }, {
    path: "/sa-de",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___sa-de"
  }, {
    path: "/sa-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___sa-en"
  }, {
    path: "/sa-es",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___sa-es"
  }, {
    path: "/sa-fr",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___sa-fr"
  }, {
    path: "/sa-it",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___sa-it"
  }, {
    path: "/uk-de",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___uk-de"
  }, {
    path: "/uk-en",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___uk-en"
  }, {
    path: "/uk-es",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___uk-es"
  }, {
    path: "/uk-fr",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___uk-fr"
  }, {
    path: "/uk-it",
    component: _8f04537e,
    meta: {"headerColor":"white"},
    name: "home___uk-it"
  }, {
    path: "/ae-de/about",
    component: _632e98b3,
    name: "about___ae-de"
  }, {
    path: "/ae-de/blog",
    component: _a3ec10f8,
    name: "blog___ae-de"
  }, {
    path: "/ae-de/Blog",
    component: _a3ec10f8,
    name: "Blog___ae-de"
  }, {
    path: "/ae-de/BlogPost",
    component: _78974e44,
    name: "BlogPost___ae-de"
  }, {
    path: "/ae-de/cart",
    component: _544757fc,
    name: "cart___ae-de"
  }, {
    path: "/ae-de/Cart",
    component: _544757fc,
    name: "Cart___ae-de"
  }, {
    path: "/ae-de/Category",
    component: _0b2841e0,
    name: "Category___ae-de"
  }, {
    path: "/ae-de/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ae-de",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ae-de"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ae-de"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ae-de"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ae-de"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ae-de"
    }]
  }, {
    path: "/ae-de/Checkout",
    component: _48ef6e28,
    name: "Checkout___ae-de",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ae-de"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ae-de"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ae-de"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ae-de"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ae-de"
    }]
  }, {
    path: "/ae-de/cms-preview",
    component: _35347821,
    name: "cms-preview___ae-de"
  }, {
    path: "/ae-de/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ae-de"
  }, {
    path: "/ae-de/contact",
    component: _b1da17a4,
    name: "contact-fr___ae-de"
  }, {
    path: "/ae-de/contact",
    component: _b1da17a4,
    name: "contact___ae-de"
  }, {
    path: "/ae-de/contact",
    component: _b1da17a4,
    name: "contact___ae-de"
  }, {
    path: "/ae-de/Contact",
    component: _b1da17a4,
    name: "Contact___ae-de"
  }, {
    path: "/ae-de/contacto",
    component: _b1da17a4,
    name: "contact-es___ae-de"
  }, {
    path: "/ae-de/contract",
    component: _c90f5248,
    name: "contract___ae-de"
  }, {
    path: "/ae-de/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ae-de"
  }, {
    path: "/ae-de/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ae-de"
  }, {
    path: "/ae-de/Home",
    component: _8f04537e,
    name: "Home___ae-de"
  }, {
    path: "/ae-de/kontakt",
    component: _b1da17a4,
    name: "contact-de___ae-de"
  }, {
    path: "/ae-de/lavorazione",
    component: _deef8500,
    name: "lavorazione___ae-de"
  }, {
    path: "/ae-de/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ae-de"
  }, {
    path: "/ae-de/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ae-de"
  }, {
    path: "/ae-de/MyAccount",
    component: _10189cef,
    name: "MyAccount___ae-de",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ae-de"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ae-de"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ae-de"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ae-de"
    }]
  }, {
    path: "/ae-de/Page",
    component: _08b2ebf1,
    name: "Page___ae-de"
  }, {
    path: "/ae-de/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ae-de"
  }, {
    path: "/ae-de/Playground",
    component: _71483cc6,
    name: "Playground___ae-de"
  }, {
    path: "/ae-de/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ae-de"
  }, {
    path: "/ae-de/Product",
    component: _4bba9d7d,
    name: "Product___ae-de"
  }, {
    path: "/ae-de/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ae-de"
  }, {
    path: "/ae-de/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ae-de"
  }, {
    path: "/ae-de/Route",
    component: _34cba952,
    name: "Route___ae-de"
  }, {
    path: "/ae-de/search",
    component: _6f8b500a,
    name: "search___ae-de"
  }, {
    path: "/ae-de/Search",
    component: _6f8b500a,
    name: "Search___ae-de"
  }, {
    path: "/ae-de/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ae-de"
  }, {
    path: "/ae-de/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ae-de"
  }, {
    path: "/ae-de/uber-uns",
    component: _632e98b3,
    name: "about-de___ae-de"
  }, {
    path: "/ae-de/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ae-de"
  }, {
    path: "/ae-de/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ae-de"
  }, {
    path: "/ae-en/about",
    component: _632e98b3,
    name: "about___ae-en"
  }, {
    path: "/ae-en/blog",
    component: _a3ec10f8,
    name: "blog___ae-en"
  }, {
    path: "/ae-en/Blog",
    component: _a3ec10f8,
    name: "Blog___ae-en"
  }, {
    path: "/ae-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___ae-en"
  }, {
    path: "/ae-en/cart",
    component: _544757fc,
    name: "cart___ae-en"
  }, {
    path: "/ae-en/Cart",
    component: _544757fc,
    name: "Cart___ae-en"
  }, {
    path: "/ae-en/Category",
    component: _0b2841e0,
    name: "Category___ae-en"
  }, {
    path: "/ae-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ae-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ae-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ae-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ae-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ae-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ae-en"
    }]
  }, {
    path: "/ae-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___ae-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ae-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ae-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ae-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ae-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ae-en"
    }]
  }, {
    path: "/ae-en/cms-preview",
    component: _35347821,
    name: "cms-preview___ae-en"
  }, {
    path: "/ae-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ae-en"
  }, {
    path: "/ae-en/contact",
    component: _b1da17a4,
    name: "contact-fr___ae-en"
  }, {
    path: "/ae-en/contact",
    component: _b1da17a4,
    name: "contact___ae-en"
  }, {
    path: "/ae-en/contact",
    component: _b1da17a4,
    name: "contact___ae-en"
  }, {
    path: "/ae-en/Contact",
    component: _b1da17a4,
    name: "Contact___ae-en"
  }, {
    path: "/ae-en/contacto",
    component: _b1da17a4,
    name: "contact-es___ae-en"
  }, {
    path: "/ae-en/contract",
    component: _c90f5248,
    name: "contract___ae-en"
  }, {
    path: "/ae-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ae-en"
  }, {
    path: "/ae-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ae-en"
  }, {
    path: "/ae-en/Home",
    component: _8f04537e,
    name: "Home___ae-en"
  }, {
    path: "/ae-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___ae-en"
  }, {
    path: "/ae-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___ae-en"
  }, {
    path: "/ae-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ae-en"
  }, {
    path: "/ae-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ae-en"
  }, {
    path: "/ae-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___ae-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ae-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ae-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ae-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ae-en"
    }]
  }, {
    path: "/ae-en/Page",
    component: _08b2ebf1,
    name: "Page___ae-en"
  }, {
    path: "/ae-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ae-en"
  }, {
    path: "/ae-en/Playground",
    component: _71483cc6,
    name: "Playground___ae-en"
  }, {
    path: "/ae-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ae-en"
  }, {
    path: "/ae-en/Product",
    component: _4bba9d7d,
    name: "Product___ae-en"
  }, {
    path: "/ae-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ae-en"
  }, {
    path: "/ae-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ae-en"
  }, {
    path: "/ae-en/Route",
    component: _34cba952,
    name: "Route___ae-en"
  }, {
    path: "/ae-en/search",
    component: _6f8b500a,
    name: "search___ae-en"
  }, {
    path: "/ae-en/Search",
    component: _6f8b500a,
    name: "Search___ae-en"
  }, {
    path: "/ae-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ae-en"
  }, {
    path: "/ae-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ae-en"
  }, {
    path: "/ae-en/uber-uns",
    component: _632e98b3,
    name: "about-de___ae-en"
  }, {
    path: "/ae-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ae-en"
  }, {
    path: "/ae-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ae-en"
  }, {
    path: "/ae-es/about",
    component: _632e98b3,
    name: "about___ae-es"
  }, {
    path: "/ae-es/blog",
    component: _a3ec10f8,
    name: "blog___ae-es"
  }, {
    path: "/ae-es/Blog",
    component: _a3ec10f8,
    name: "Blog___ae-es"
  }, {
    path: "/ae-es/BlogPost",
    component: _78974e44,
    name: "BlogPost___ae-es"
  }, {
    path: "/ae-es/cart",
    component: _544757fc,
    name: "cart___ae-es"
  }, {
    path: "/ae-es/Cart",
    component: _544757fc,
    name: "Cart___ae-es"
  }, {
    path: "/ae-es/Category",
    component: _0b2841e0,
    name: "Category___ae-es"
  }, {
    path: "/ae-es/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ae-es",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ae-es"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ae-es"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ae-es"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ae-es"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ae-es"
    }]
  }, {
    path: "/ae-es/Checkout",
    component: _48ef6e28,
    name: "Checkout___ae-es",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ae-es"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ae-es"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ae-es"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ae-es"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ae-es"
    }]
  }, {
    path: "/ae-es/cms-preview",
    component: _35347821,
    name: "cms-preview___ae-es"
  }, {
    path: "/ae-es/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ae-es"
  }, {
    path: "/ae-es/contact",
    component: _b1da17a4,
    name: "contact-fr___ae-es"
  }, {
    path: "/ae-es/contact",
    component: _b1da17a4,
    name: "contact___ae-es"
  }, {
    path: "/ae-es/contact",
    component: _b1da17a4,
    name: "contact___ae-es"
  }, {
    path: "/ae-es/Contact",
    component: _b1da17a4,
    name: "Contact___ae-es"
  }, {
    path: "/ae-es/contacto",
    component: _b1da17a4,
    name: "contact-es___ae-es"
  }, {
    path: "/ae-es/contract",
    component: _c90f5248,
    name: "contract___ae-es"
  }, {
    path: "/ae-es/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ae-es"
  }, {
    path: "/ae-es/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ae-es"
  }, {
    path: "/ae-es/Home",
    component: _8f04537e,
    name: "Home___ae-es"
  }, {
    path: "/ae-es/kontakt",
    component: _b1da17a4,
    name: "contact-de___ae-es"
  }, {
    path: "/ae-es/lavorazione",
    component: _deef8500,
    name: "lavorazione___ae-es"
  }, {
    path: "/ae-es/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ae-es"
  }, {
    path: "/ae-es/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ae-es"
  }, {
    path: "/ae-es/MyAccount",
    component: _10189cef,
    name: "MyAccount___ae-es",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ae-es"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ae-es"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ae-es"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ae-es"
    }]
  }, {
    path: "/ae-es/Page",
    component: _08b2ebf1,
    name: "Page___ae-es"
  }, {
    path: "/ae-es/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ae-es"
  }, {
    path: "/ae-es/Playground",
    component: _71483cc6,
    name: "Playground___ae-es"
  }, {
    path: "/ae-es/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ae-es"
  }, {
    path: "/ae-es/Product",
    component: _4bba9d7d,
    name: "Product___ae-es"
  }, {
    path: "/ae-es/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ae-es"
  }, {
    path: "/ae-es/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ae-es"
  }, {
    path: "/ae-es/Route",
    component: _34cba952,
    name: "Route___ae-es"
  }, {
    path: "/ae-es/search",
    component: _6f8b500a,
    name: "search___ae-es"
  }, {
    path: "/ae-es/Search",
    component: _6f8b500a,
    name: "Search___ae-es"
  }, {
    path: "/ae-es/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ae-es"
  }, {
    path: "/ae-es/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ae-es"
  }, {
    path: "/ae-es/uber-uns",
    component: _632e98b3,
    name: "about-de___ae-es"
  }, {
    path: "/ae-es/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ae-es"
  }, {
    path: "/ae-es/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ae-es"
  }, {
    path: "/ae-fr/about",
    component: _632e98b3,
    name: "about___ae-fr"
  }, {
    path: "/ae-fr/blog",
    component: _a3ec10f8,
    name: "blog___ae-fr"
  }, {
    path: "/ae-fr/Blog",
    component: _a3ec10f8,
    name: "Blog___ae-fr"
  }, {
    path: "/ae-fr/BlogPost",
    component: _78974e44,
    name: "BlogPost___ae-fr"
  }, {
    path: "/ae-fr/cart",
    component: _544757fc,
    name: "cart___ae-fr"
  }, {
    path: "/ae-fr/Cart",
    component: _544757fc,
    name: "Cart___ae-fr"
  }, {
    path: "/ae-fr/Category",
    component: _0b2841e0,
    name: "Category___ae-fr"
  }, {
    path: "/ae-fr/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ae-fr",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ae-fr"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ae-fr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ae-fr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ae-fr"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ae-fr"
    }]
  }, {
    path: "/ae-fr/Checkout",
    component: _48ef6e28,
    name: "Checkout___ae-fr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ae-fr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ae-fr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ae-fr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ae-fr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ae-fr"
    }]
  }, {
    path: "/ae-fr/cms-preview",
    component: _35347821,
    name: "cms-preview___ae-fr"
  }, {
    path: "/ae-fr/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ae-fr"
  }, {
    path: "/ae-fr/contact",
    component: _b1da17a4,
    name: "contact-fr___ae-fr"
  }, {
    path: "/ae-fr/contact",
    component: _b1da17a4,
    name: "contact___ae-fr"
  }, {
    path: "/ae-fr/contact",
    component: _b1da17a4,
    name: "contact___ae-fr"
  }, {
    path: "/ae-fr/Contact",
    component: _b1da17a4,
    name: "Contact___ae-fr"
  }, {
    path: "/ae-fr/contacto",
    component: _b1da17a4,
    name: "contact-es___ae-fr"
  }, {
    path: "/ae-fr/contract",
    component: _c90f5248,
    name: "contract___ae-fr"
  }, {
    path: "/ae-fr/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ae-fr"
  }, {
    path: "/ae-fr/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ae-fr"
  }, {
    path: "/ae-fr/Home",
    component: _8f04537e,
    name: "Home___ae-fr"
  }, {
    path: "/ae-fr/kontakt",
    component: _b1da17a4,
    name: "contact-de___ae-fr"
  }, {
    path: "/ae-fr/lavorazione",
    component: _deef8500,
    name: "lavorazione___ae-fr"
  }, {
    path: "/ae-fr/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ae-fr"
  }, {
    path: "/ae-fr/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ae-fr"
  }, {
    path: "/ae-fr/MyAccount",
    component: _10189cef,
    name: "MyAccount___ae-fr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ae-fr"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ae-fr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ae-fr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ae-fr"
    }]
  }, {
    path: "/ae-fr/Page",
    component: _08b2ebf1,
    name: "Page___ae-fr"
  }, {
    path: "/ae-fr/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ae-fr"
  }, {
    path: "/ae-fr/Playground",
    component: _71483cc6,
    name: "Playground___ae-fr"
  }, {
    path: "/ae-fr/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ae-fr"
  }, {
    path: "/ae-fr/Product",
    component: _4bba9d7d,
    name: "Product___ae-fr"
  }, {
    path: "/ae-fr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ae-fr"
  }, {
    path: "/ae-fr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ae-fr"
  }, {
    path: "/ae-fr/Route",
    component: _34cba952,
    name: "Route___ae-fr"
  }, {
    path: "/ae-fr/search",
    component: _6f8b500a,
    name: "search___ae-fr"
  }, {
    path: "/ae-fr/Search",
    component: _6f8b500a,
    name: "Search___ae-fr"
  }, {
    path: "/ae-fr/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ae-fr"
  }, {
    path: "/ae-fr/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ae-fr"
  }, {
    path: "/ae-fr/uber-uns",
    component: _632e98b3,
    name: "about-de___ae-fr"
  }, {
    path: "/ae-fr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ae-fr"
  }, {
    path: "/ae-fr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ae-fr"
  }, {
    path: "/ae-it/about",
    component: _632e98b3,
    name: "about___ae-it"
  }, {
    path: "/ae-it/blog",
    component: _a3ec10f8,
    name: "blog___ae-it"
  }, {
    path: "/ae-it/Blog",
    component: _a3ec10f8,
    name: "Blog___ae-it"
  }, {
    path: "/ae-it/BlogPost",
    component: _78974e44,
    name: "BlogPost___ae-it"
  }, {
    path: "/ae-it/cart",
    component: _544757fc,
    name: "cart___ae-it"
  }, {
    path: "/ae-it/Cart",
    component: _544757fc,
    name: "Cart___ae-it"
  }, {
    path: "/ae-it/Category",
    component: _0b2841e0,
    name: "Category___ae-it"
  }, {
    path: "/ae-it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ae-it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ae-it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ae-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ae-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ae-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ae-it"
    }]
  }, {
    path: "/ae-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___ae-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ae-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ae-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ae-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ae-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ae-it"
    }]
  }, {
    path: "/ae-it/cms-preview",
    component: _35347821,
    name: "cms-preview___ae-it"
  }, {
    path: "/ae-it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ae-it"
  }, {
    path: "/ae-it/contact",
    component: _b1da17a4,
    name: "contact-fr___ae-it"
  }, {
    path: "/ae-it/contact",
    component: _b1da17a4,
    name: "contact___ae-it"
  }, {
    path: "/ae-it/contact",
    component: _b1da17a4,
    name: "contact___ae-it"
  }, {
    path: "/ae-it/Contact",
    component: _b1da17a4,
    name: "Contact___ae-it"
  }, {
    path: "/ae-it/contacto",
    component: _b1da17a4,
    name: "contact-es___ae-it"
  }, {
    path: "/ae-it/contract",
    component: _c90f5248,
    name: "contract___ae-it"
  }, {
    path: "/ae-it/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ae-it"
  }, {
    path: "/ae-it/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ae-it"
  }, {
    path: "/ae-it/Home",
    component: _8f04537e,
    name: "Home___ae-it"
  }, {
    path: "/ae-it/kontakt",
    component: _b1da17a4,
    name: "contact-de___ae-it"
  }, {
    path: "/ae-it/lavorazione",
    component: _deef8500,
    name: "lavorazione___ae-it"
  }, {
    path: "/ae-it/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ae-it"
  }, {
    path: "/ae-it/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ae-it"
  }, {
    path: "/ae-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___ae-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ae-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ae-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ae-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ae-it"
    }]
  }, {
    path: "/ae-it/Page",
    component: _08b2ebf1,
    name: "Page___ae-it"
  }, {
    path: "/ae-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ae-it"
  }, {
    path: "/ae-it/Playground",
    component: _71483cc6,
    name: "Playground___ae-it"
  }, {
    path: "/ae-it/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ae-it"
  }, {
    path: "/ae-it/Product",
    component: _4bba9d7d,
    name: "Product___ae-it"
  }, {
    path: "/ae-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ae-it"
  }, {
    path: "/ae-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ae-it"
  }, {
    path: "/ae-it/Route",
    component: _34cba952,
    name: "Route___ae-it"
  }, {
    path: "/ae-it/search",
    component: _6f8b500a,
    name: "search___ae-it"
  }, {
    path: "/ae-it/Search",
    component: _6f8b500a,
    name: "Search___ae-it"
  }, {
    path: "/ae-it/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ae-it"
  }, {
    path: "/ae-it/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ae-it"
  }, {
    path: "/ae-it/uber-uns",
    component: _632e98b3,
    name: "about-de___ae-it"
  }, {
    path: "/ae-it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ae-it"
  }, {
    path: "/ae-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ae-it"
  }, {
    path: "/ch-de/about",
    component: _632e98b3,
    name: "about___ch-de"
  }, {
    path: "/ch-de/blog",
    component: _a3ec10f8,
    name: "blog___ch-de"
  }, {
    path: "/ch-de/Blog",
    component: _a3ec10f8,
    name: "Blog___ch-de"
  }, {
    path: "/ch-de/BlogPost",
    component: _78974e44,
    name: "BlogPost___ch-de"
  }, {
    path: "/ch-de/cart",
    component: _544757fc,
    name: "cart___ch-de"
  }, {
    path: "/ch-de/Cart",
    component: _544757fc,
    name: "Cart___ch-de"
  }, {
    path: "/ch-de/Category",
    component: _0b2841e0,
    name: "Category___ch-de"
  }, {
    path: "/ch-de/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ch-de",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ch-de"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ch-de"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ch-de"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ch-de"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ch-de"
    }]
  }, {
    path: "/ch-de/Checkout",
    component: _48ef6e28,
    name: "Checkout___ch-de",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ch-de"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ch-de"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ch-de"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ch-de"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ch-de"
    }]
  }, {
    path: "/ch-de/cms-preview",
    component: _35347821,
    name: "cms-preview___ch-de"
  }, {
    path: "/ch-de/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ch-de"
  }, {
    path: "/ch-de/contact",
    component: _b1da17a4,
    name: "contact-fr___ch-de"
  }, {
    path: "/ch-de/contact",
    component: _b1da17a4,
    name: "contact___ch-de"
  }, {
    path: "/ch-de/contact",
    component: _b1da17a4,
    name: "contact___ch-de"
  }, {
    path: "/ch-de/Contact",
    component: _b1da17a4,
    name: "Contact___ch-de"
  }, {
    path: "/ch-de/contacto",
    component: _b1da17a4,
    name: "contact-es___ch-de"
  }, {
    path: "/ch-de/contract",
    component: _c90f5248,
    name: "contract___ch-de"
  }, {
    path: "/ch-de/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ch-de"
  }, {
    path: "/ch-de/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ch-de"
  }, {
    path: "/ch-de/Home",
    component: _8f04537e,
    name: "Home___ch-de"
  }, {
    path: "/ch-de/kontakt",
    component: _b1da17a4,
    name: "contact-de___ch-de"
  }, {
    path: "/ch-de/lavorazione",
    component: _deef8500,
    name: "lavorazione___ch-de"
  }, {
    path: "/ch-de/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ch-de"
  }, {
    path: "/ch-de/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ch-de"
  }, {
    path: "/ch-de/MyAccount",
    component: _10189cef,
    name: "MyAccount___ch-de",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ch-de"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ch-de"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ch-de"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ch-de"
    }]
  }, {
    path: "/ch-de/Page",
    component: _08b2ebf1,
    name: "Page___ch-de"
  }, {
    path: "/ch-de/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ch-de"
  }, {
    path: "/ch-de/Playground",
    component: _71483cc6,
    name: "Playground___ch-de"
  }, {
    path: "/ch-de/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ch-de"
  }, {
    path: "/ch-de/Product",
    component: _4bba9d7d,
    name: "Product___ch-de"
  }, {
    path: "/ch-de/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ch-de"
  }, {
    path: "/ch-de/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ch-de"
  }, {
    path: "/ch-de/Route",
    component: _34cba952,
    name: "Route___ch-de"
  }, {
    path: "/ch-de/search",
    component: _6f8b500a,
    name: "search___ch-de"
  }, {
    path: "/ch-de/Search",
    component: _6f8b500a,
    name: "Search___ch-de"
  }, {
    path: "/ch-de/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ch-de"
  }, {
    path: "/ch-de/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ch-de"
  }, {
    path: "/ch-de/uber-uns",
    component: _632e98b3,
    name: "about-de___ch-de"
  }, {
    path: "/ch-de/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ch-de"
  }, {
    path: "/ch-de/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ch-de"
  }, {
    path: "/ch-en/about",
    component: _632e98b3,
    name: "about___ch-en"
  }, {
    path: "/ch-en/blog",
    component: _a3ec10f8,
    name: "blog___ch-en"
  }, {
    path: "/ch-en/Blog",
    component: _a3ec10f8,
    name: "Blog___ch-en"
  }, {
    path: "/ch-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___ch-en"
  }, {
    path: "/ch-en/cart",
    component: _544757fc,
    name: "cart___ch-en"
  }, {
    path: "/ch-en/Cart",
    component: _544757fc,
    name: "Cart___ch-en"
  }, {
    path: "/ch-en/Category",
    component: _0b2841e0,
    name: "Category___ch-en"
  }, {
    path: "/ch-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ch-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ch-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ch-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ch-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ch-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ch-en"
    }]
  }, {
    path: "/ch-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___ch-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ch-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ch-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ch-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ch-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ch-en"
    }]
  }, {
    path: "/ch-en/cms-preview",
    component: _35347821,
    name: "cms-preview___ch-en"
  }, {
    path: "/ch-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ch-en"
  }, {
    path: "/ch-en/contact",
    component: _b1da17a4,
    name: "contact-fr___ch-en"
  }, {
    path: "/ch-en/contact",
    component: _b1da17a4,
    name: "contact___ch-en"
  }, {
    path: "/ch-en/contact",
    component: _b1da17a4,
    name: "contact___ch-en"
  }, {
    path: "/ch-en/Contact",
    component: _b1da17a4,
    name: "Contact___ch-en"
  }, {
    path: "/ch-en/contacto",
    component: _b1da17a4,
    name: "contact-es___ch-en"
  }, {
    path: "/ch-en/contract",
    component: _c90f5248,
    name: "contract___ch-en"
  }, {
    path: "/ch-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ch-en"
  }, {
    path: "/ch-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ch-en"
  }, {
    path: "/ch-en/Home",
    component: _8f04537e,
    name: "Home___ch-en"
  }, {
    path: "/ch-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___ch-en"
  }, {
    path: "/ch-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___ch-en"
  }, {
    path: "/ch-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ch-en"
  }, {
    path: "/ch-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ch-en"
  }, {
    path: "/ch-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___ch-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ch-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ch-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ch-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ch-en"
    }]
  }, {
    path: "/ch-en/Page",
    component: _08b2ebf1,
    name: "Page___ch-en"
  }, {
    path: "/ch-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ch-en"
  }, {
    path: "/ch-en/Playground",
    component: _71483cc6,
    name: "Playground___ch-en"
  }, {
    path: "/ch-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ch-en"
  }, {
    path: "/ch-en/Product",
    component: _4bba9d7d,
    name: "Product___ch-en"
  }, {
    path: "/ch-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ch-en"
  }, {
    path: "/ch-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ch-en"
  }, {
    path: "/ch-en/Route",
    component: _34cba952,
    name: "Route___ch-en"
  }, {
    path: "/ch-en/search",
    component: _6f8b500a,
    name: "search___ch-en"
  }, {
    path: "/ch-en/Search",
    component: _6f8b500a,
    name: "Search___ch-en"
  }, {
    path: "/ch-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ch-en"
  }, {
    path: "/ch-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ch-en"
  }, {
    path: "/ch-en/uber-uns",
    component: _632e98b3,
    name: "about-de___ch-en"
  }, {
    path: "/ch-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ch-en"
  }, {
    path: "/ch-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ch-en"
  }, {
    path: "/ch-es/about",
    component: _632e98b3,
    name: "about___ch-es"
  }, {
    path: "/ch-es/blog",
    component: _a3ec10f8,
    name: "blog___ch-es"
  }, {
    path: "/ch-es/Blog",
    component: _a3ec10f8,
    name: "Blog___ch-es"
  }, {
    path: "/ch-es/BlogPost",
    component: _78974e44,
    name: "BlogPost___ch-es"
  }, {
    path: "/ch-es/cart",
    component: _544757fc,
    name: "cart___ch-es"
  }, {
    path: "/ch-es/Cart",
    component: _544757fc,
    name: "Cart___ch-es"
  }, {
    path: "/ch-es/Category",
    component: _0b2841e0,
    name: "Category___ch-es"
  }, {
    path: "/ch-es/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ch-es",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ch-es"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ch-es"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ch-es"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ch-es"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ch-es"
    }]
  }, {
    path: "/ch-es/Checkout",
    component: _48ef6e28,
    name: "Checkout___ch-es",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ch-es"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ch-es"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ch-es"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ch-es"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ch-es"
    }]
  }, {
    path: "/ch-es/cms-preview",
    component: _35347821,
    name: "cms-preview___ch-es"
  }, {
    path: "/ch-es/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ch-es"
  }, {
    path: "/ch-es/contact",
    component: _b1da17a4,
    name: "contact-fr___ch-es"
  }, {
    path: "/ch-es/contact",
    component: _b1da17a4,
    name: "contact___ch-es"
  }, {
    path: "/ch-es/contact",
    component: _b1da17a4,
    name: "contact___ch-es"
  }, {
    path: "/ch-es/Contact",
    component: _b1da17a4,
    name: "Contact___ch-es"
  }, {
    path: "/ch-es/contacto",
    component: _b1da17a4,
    name: "contact-es___ch-es"
  }, {
    path: "/ch-es/contract",
    component: _c90f5248,
    name: "contract___ch-es"
  }, {
    path: "/ch-es/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ch-es"
  }, {
    path: "/ch-es/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ch-es"
  }, {
    path: "/ch-es/Home",
    component: _8f04537e,
    name: "Home___ch-es"
  }, {
    path: "/ch-es/kontakt",
    component: _b1da17a4,
    name: "contact-de___ch-es"
  }, {
    path: "/ch-es/lavorazione",
    component: _deef8500,
    name: "lavorazione___ch-es"
  }, {
    path: "/ch-es/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ch-es"
  }, {
    path: "/ch-es/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ch-es"
  }, {
    path: "/ch-es/MyAccount",
    component: _10189cef,
    name: "MyAccount___ch-es",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ch-es"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ch-es"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ch-es"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ch-es"
    }]
  }, {
    path: "/ch-es/Page",
    component: _08b2ebf1,
    name: "Page___ch-es"
  }, {
    path: "/ch-es/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ch-es"
  }, {
    path: "/ch-es/Playground",
    component: _71483cc6,
    name: "Playground___ch-es"
  }, {
    path: "/ch-es/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ch-es"
  }, {
    path: "/ch-es/Product",
    component: _4bba9d7d,
    name: "Product___ch-es"
  }, {
    path: "/ch-es/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ch-es"
  }, {
    path: "/ch-es/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ch-es"
  }, {
    path: "/ch-es/Route",
    component: _34cba952,
    name: "Route___ch-es"
  }, {
    path: "/ch-es/search",
    component: _6f8b500a,
    name: "search___ch-es"
  }, {
    path: "/ch-es/Search",
    component: _6f8b500a,
    name: "Search___ch-es"
  }, {
    path: "/ch-es/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ch-es"
  }, {
    path: "/ch-es/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ch-es"
  }, {
    path: "/ch-es/uber-uns",
    component: _632e98b3,
    name: "about-de___ch-es"
  }, {
    path: "/ch-es/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ch-es"
  }, {
    path: "/ch-es/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ch-es"
  }, {
    path: "/ch-fr/about",
    component: _632e98b3,
    name: "about___ch-fr"
  }, {
    path: "/ch-fr/blog",
    component: _a3ec10f8,
    name: "blog___ch-fr"
  }, {
    path: "/ch-fr/Blog",
    component: _a3ec10f8,
    name: "Blog___ch-fr"
  }, {
    path: "/ch-fr/BlogPost",
    component: _78974e44,
    name: "BlogPost___ch-fr"
  }, {
    path: "/ch-fr/cart",
    component: _544757fc,
    name: "cart___ch-fr"
  }, {
    path: "/ch-fr/Cart",
    component: _544757fc,
    name: "Cart___ch-fr"
  }, {
    path: "/ch-fr/Category",
    component: _0b2841e0,
    name: "Category___ch-fr"
  }, {
    path: "/ch-fr/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ch-fr",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ch-fr"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ch-fr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ch-fr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ch-fr"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ch-fr"
    }]
  }, {
    path: "/ch-fr/Checkout",
    component: _48ef6e28,
    name: "Checkout___ch-fr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ch-fr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ch-fr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ch-fr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ch-fr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ch-fr"
    }]
  }, {
    path: "/ch-fr/cms-preview",
    component: _35347821,
    name: "cms-preview___ch-fr"
  }, {
    path: "/ch-fr/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ch-fr"
  }, {
    path: "/ch-fr/contact",
    component: _b1da17a4,
    name: "contact-fr___ch-fr"
  }, {
    path: "/ch-fr/contact",
    component: _b1da17a4,
    name: "contact___ch-fr"
  }, {
    path: "/ch-fr/contact",
    component: _b1da17a4,
    name: "contact___ch-fr"
  }, {
    path: "/ch-fr/Contact",
    component: _b1da17a4,
    name: "Contact___ch-fr"
  }, {
    path: "/ch-fr/contacto",
    component: _b1da17a4,
    name: "contact-es___ch-fr"
  }, {
    path: "/ch-fr/contract",
    component: _c90f5248,
    name: "contract___ch-fr"
  }, {
    path: "/ch-fr/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ch-fr"
  }, {
    path: "/ch-fr/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ch-fr"
  }, {
    path: "/ch-fr/Home",
    component: _8f04537e,
    name: "Home___ch-fr"
  }, {
    path: "/ch-fr/kontakt",
    component: _b1da17a4,
    name: "contact-de___ch-fr"
  }, {
    path: "/ch-fr/lavorazione",
    component: _deef8500,
    name: "lavorazione___ch-fr"
  }, {
    path: "/ch-fr/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ch-fr"
  }, {
    path: "/ch-fr/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ch-fr"
  }, {
    path: "/ch-fr/MyAccount",
    component: _10189cef,
    name: "MyAccount___ch-fr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ch-fr"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ch-fr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ch-fr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ch-fr"
    }]
  }, {
    path: "/ch-fr/Page",
    component: _08b2ebf1,
    name: "Page___ch-fr"
  }, {
    path: "/ch-fr/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ch-fr"
  }, {
    path: "/ch-fr/Playground",
    component: _71483cc6,
    name: "Playground___ch-fr"
  }, {
    path: "/ch-fr/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ch-fr"
  }, {
    path: "/ch-fr/Product",
    component: _4bba9d7d,
    name: "Product___ch-fr"
  }, {
    path: "/ch-fr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ch-fr"
  }, {
    path: "/ch-fr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ch-fr"
  }, {
    path: "/ch-fr/Route",
    component: _34cba952,
    name: "Route___ch-fr"
  }, {
    path: "/ch-fr/search",
    component: _6f8b500a,
    name: "search___ch-fr"
  }, {
    path: "/ch-fr/Search",
    component: _6f8b500a,
    name: "Search___ch-fr"
  }, {
    path: "/ch-fr/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ch-fr"
  }, {
    path: "/ch-fr/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ch-fr"
  }, {
    path: "/ch-fr/uber-uns",
    component: _632e98b3,
    name: "about-de___ch-fr"
  }, {
    path: "/ch-fr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ch-fr"
  }, {
    path: "/ch-fr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ch-fr"
  }, {
    path: "/ch-it/about",
    component: _632e98b3,
    name: "about___ch-it"
  }, {
    path: "/ch-it/blog",
    component: _a3ec10f8,
    name: "blog___ch-it"
  }, {
    path: "/ch-it/Blog",
    component: _a3ec10f8,
    name: "Blog___ch-it"
  }, {
    path: "/ch-it/BlogPost",
    component: _78974e44,
    name: "BlogPost___ch-it"
  }, {
    path: "/ch-it/cart",
    component: _544757fc,
    name: "cart___ch-it"
  }, {
    path: "/ch-it/Cart",
    component: _544757fc,
    name: "Cart___ch-it"
  }, {
    path: "/ch-it/Category",
    component: _0b2841e0,
    name: "Category___ch-it"
  }, {
    path: "/ch-it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___ch-it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___ch-it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___ch-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___ch-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___ch-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___ch-it"
    }]
  }, {
    path: "/ch-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___ch-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ch-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ch-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ch-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ch-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ch-it"
    }]
  }, {
    path: "/ch-it/cms-preview",
    component: _35347821,
    name: "cms-preview___ch-it"
  }, {
    path: "/ch-it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___ch-it"
  }, {
    path: "/ch-it/contact",
    component: _b1da17a4,
    name: "contact-fr___ch-it"
  }, {
    path: "/ch-it/contact",
    component: _b1da17a4,
    name: "contact___ch-it"
  }, {
    path: "/ch-it/contact",
    component: _b1da17a4,
    name: "contact___ch-it"
  }, {
    path: "/ch-it/Contact",
    component: _b1da17a4,
    name: "Contact___ch-it"
  }, {
    path: "/ch-it/contacto",
    component: _b1da17a4,
    name: "contact-es___ch-it"
  }, {
    path: "/ch-it/contract",
    component: _c90f5248,
    name: "contract___ch-it"
  }, {
    path: "/ch-it/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___ch-it"
  }, {
    path: "/ch-it/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___ch-it"
  }, {
    path: "/ch-it/Home",
    component: _8f04537e,
    name: "Home___ch-it"
  }, {
    path: "/ch-it/kontakt",
    component: _b1da17a4,
    name: "contact-de___ch-it"
  }, {
    path: "/ch-it/lavorazione",
    component: _deef8500,
    name: "lavorazione___ch-it"
  }, {
    path: "/ch-it/listing-preview",
    component: _3dc44706,
    name: "listing-preview___ch-it"
  }, {
    path: "/ch-it/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___ch-it"
  }, {
    path: "/ch-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___ch-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ch-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ch-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ch-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ch-it"
    }]
  }, {
    path: "/ch-it/Page",
    component: _08b2ebf1,
    name: "Page___ch-it"
  }, {
    path: "/ch-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ch-it"
  }, {
    path: "/ch-it/Playground",
    component: _71483cc6,
    name: "Playground___ch-it"
  }, {
    path: "/ch-it/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___ch-it"
  }, {
    path: "/ch-it/Product",
    component: _4bba9d7d,
    name: "Product___ch-it"
  }, {
    path: "/ch-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ch-it"
  }, {
    path: "/ch-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ch-it"
  }, {
    path: "/ch-it/Route",
    component: _34cba952,
    name: "Route___ch-it"
  }, {
    path: "/ch-it/search",
    component: _6f8b500a,
    name: "search___ch-it"
  }, {
    path: "/ch-it/Search",
    component: _6f8b500a,
    name: "Search___ch-it"
  }, {
    path: "/ch-it/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___ch-it"
  }, {
    path: "/ch-it/traitement",
    component: _deef8500,
    name: "lavorazione-fr___ch-it"
  }, {
    path: "/ch-it/uber-uns",
    component: _632e98b3,
    name: "about-de___ch-it"
  }, {
    path: "/ch-it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ch-it"
  }, {
    path: "/ch-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ch-it"
  }, {
    path: "/eu-de/about",
    component: _632e98b3,
    name: "about___eu-de"
  }, {
    path: "/eu-de/blog",
    component: _a3ec10f8,
    name: "blog___eu-de"
  }, {
    path: "/eu-de/Blog",
    component: _a3ec10f8,
    name: "Blog___eu-de"
  }, {
    path: "/eu-de/BlogPost",
    component: _78974e44,
    name: "BlogPost___eu-de"
  }, {
    path: "/eu-de/cart",
    component: _544757fc,
    name: "cart___eu-de"
  }, {
    path: "/eu-de/Cart",
    component: _544757fc,
    name: "Cart___eu-de"
  }, {
    path: "/eu-de/Category",
    component: _0b2841e0,
    name: "Category___eu-de"
  }, {
    path: "/eu-de/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___eu-de",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___eu-de"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___eu-de"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___eu-de"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___eu-de"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___eu-de"
    }]
  }, {
    path: "/eu-de/Checkout",
    component: _48ef6e28,
    name: "Checkout___eu-de",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eu-de"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eu-de"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eu-de"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eu-de"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eu-de"
    }]
  }, {
    path: "/eu-de/cms-preview",
    component: _35347821,
    name: "cms-preview___eu-de"
  }, {
    path: "/eu-de/CmsPreview",
    component: _35347821,
    name: "CmsPreview___eu-de"
  }, {
    path: "/eu-de/contact",
    component: _b1da17a4,
    name: "contact-fr___eu-de"
  }, {
    path: "/eu-de/contact",
    component: _b1da17a4,
    name: "contact___eu-de"
  }, {
    path: "/eu-de/contact",
    component: _b1da17a4,
    name: "contact___eu-de"
  }, {
    path: "/eu-de/Contact",
    component: _b1da17a4,
    name: "Contact___eu-de"
  }, {
    path: "/eu-de/contacto",
    component: _b1da17a4,
    name: "contact-es___eu-de"
  }, {
    path: "/eu-de/contract",
    component: _c90f5248,
    name: "contract___eu-de"
  }, {
    path: "/eu-de/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___eu-de"
  }, {
    path: "/eu-de/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___eu-de"
  }, {
    path: "/eu-de/Home",
    component: _8f04537e,
    name: "Home___eu-de"
  }, {
    path: "/eu-de/kontakt",
    component: _b1da17a4,
    name: "contact-de___eu-de"
  }, {
    path: "/eu-de/lavorazione",
    component: _deef8500,
    name: "lavorazione___eu-de"
  }, {
    path: "/eu-de/listing-preview",
    component: _3dc44706,
    name: "listing-preview___eu-de"
  }, {
    path: "/eu-de/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___eu-de"
  }, {
    path: "/eu-de/MyAccount",
    component: _10189cef,
    name: "MyAccount___eu-de",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eu-de"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___eu-de"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eu-de"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eu-de"
    }]
  }, {
    path: "/eu-de/Page",
    component: _08b2ebf1,
    name: "Page___eu-de"
  }, {
    path: "/eu-de/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___eu-de"
  }, {
    path: "/eu-de/Playground",
    component: _71483cc6,
    name: "Playground___eu-de"
  }, {
    path: "/eu-de/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___eu-de"
  }, {
    path: "/eu-de/Product",
    component: _4bba9d7d,
    name: "Product___eu-de"
  }, {
    path: "/eu-de/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eu-de"
  }, {
    path: "/eu-de/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eu-de"
  }, {
    path: "/eu-de/Route",
    component: _34cba952,
    name: "Route___eu-de"
  }, {
    path: "/eu-de/search",
    component: _6f8b500a,
    name: "search___eu-de"
  }, {
    path: "/eu-de/Search",
    component: _6f8b500a,
    name: "Search___eu-de"
  }, {
    path: "/eu-de/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___eu-de"
  }, {
    path: "/eu-de/traitement",
    component: _deef8500,
    name: "lavorazione-fr___eu-de"
  }, {
    path: "/eu-de/uber-uns",
    component: _632e98b3,
    name: "about-de___eu-de"
  }, {
    path: "/eu-de/wishlist",
    component: _3fbd01b2,
    name: "wishlist___eu-de"
  }, {
    path: "/eu-de/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eu-de"
  }, {
    path: "/eu-en/about",
    component: _632e98b3,
    name: "about___eu-en"
  }, {
    path: "/eu-en/blog",
    component: _a3ec10f8,
    name: "blog___eu-en"
  }, {
    path: "/eu-en/Blog",
    component: _a3ec10f8,
    name: "Blog___eu-en"
  }, {
    path: "/eu-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___eu-en"
  }, {
    path: "/eu-en/cart",
    component: _544757fc,
    name: "cart___eu-en"
  }, {
    path: "/eu-en/Cart",
    component: _544757fc,
    name: "Cart___eu-en"
  }, {
    path: "/eu-en/Category",
    component: _0b2841e0,
    name: "Category___eu-en"
  }, {
    path: "/eu-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___eu-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___eu-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___eu-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___eu-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___eu-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___eu-en"
    }]
  }, {
    path: "/eu-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___eu-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eu-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eu-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eu-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eu-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eu-en"
    }]
  }, {
    path: "/eu-en/cms-preview",
    component: _35347821,
    name: "cms-preview___eu-en"
  }, {
    path: "/eu-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___eu-en"
  }, {
    path: "/eu-en/contact",
    component: _b1da17a4,
    name: "contact-fr___eu-en"
  }, {
    path: "/eu-en/contact",
    component: _b1da17a4,
    name: "contact___eu-en"
  }, {
    path: "/eu-en/contact",
    component: _b1da17a4,
    name: "contact___eu-en"
  }, {
    path: "/eu-en/Contact",
    component: _b1da17a4,
    name: "Contact___eu-en"
  }, {
    path: "/eu-en/contacto",
    component: _b1da17a4,
    name: "contact-es___eu-en"
  }, {
    path: "/eu-en/contract",
    component: _c90f5248,
    name: "contract___eu-en"
  }, {
    path: "/eu-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___eu-en"
  }, {
    path: "/eu-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___eu-en"
  }, {
    path: "/eu-en/Home",
    component: _8f04537e,
    name: "Home___eu-en"
  }, {
    path: "/eu-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___eu-en"
  }, {
    path: "/eu-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___eu-en"
  }, {
    path: "/eu-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___eu-en"
  }, {
    path: "/eu-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___eu-en"
  }, {
    path: "/eu-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___eu-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eu-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___eu-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eu-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eu-en"
    }]
  }, {
    path: "/eu-en/Page",
    component: _08b2ebf1,
    name: "Page___eu-en"
  }, {
    path: "/eu-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___eu-en"
  }, {
    path: "/eu-en/Playground",
    component: _71483cc6,
    name: "Playground___eu-en"
  }, {
    path: "/eu-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___eu-en"
  }, {
    path: "/eu-en/Product",
    component: _4bba9d7d,
    name: "Product___eu-en"
  }, {
    path: "/eu-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eu-en"
  }, {
    path: "/eu-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eu-en"
  }, {
    path: "/eu-en/Route",
    component: _34cba952,
    name: "Route___eu-en"
  }, {
    path: "/eu-en/search",
    component: _6f8b500a,
    name: "search___eu-en"
  }, {
    path: "/eu-en/Search",
    component: _6f8b500a,
    name: "Search___eu-en"
  }, {
    path: "/eu-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___eu-en"
  }, {
    path: "/eu-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___eu-en"
  }, {
    path: "/eu-en/uber-uns",
    component: _632e98b3,
    name: "about-de___eu-en"
  }, {
    path: "/eu-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___eu-en"
  }, {
    path: "/eu-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eu-en"
  }, {
    path: "/eu-es/about",
    component: _632e98b3,
    name: "about___eu-es"
  }, {
    path: "/eu-es/blog",
    component: _a3ec10f8,
    name: "blog___eu-es"
  }, {
    path: "/eu-es/Blog",
    component: _a3ec10f8,
    name: "Blog___eu-es"
  }, {
    path: "/eu-es/BlogPost",
    component: _78974e44,
    name: "BlogPost___eu-es"
  }, {
    path: "/eu-es/cart",
    component: _544757fc,
    name: "cart___eu-es"
  }, {
    path: "/eu-es/Cart",
    component: _544757fc,
    name: "Cart___eu-es"
  }, {
    path: "/eu-es/Category",
    component: _0b2841e0,
    name: "Category___eu-es"
  }, {
    path: "/eu-es/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___eu-es",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___eu-es"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___eu-es"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___eu-es"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___eu-es"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___eu-es"
    }]
  }, {
    path: "/eu-es/Checkout",
    component: _48ef6e28,
    name: "Checkout___eu-es",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eu-es"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eu-es"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eu-es"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eu-es"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eu-es"
    }]
  }, {
    path: "/eu-es/cms-preview",
    component: _35347821,
    name: "cms-preview___eu-es"
  }, {
    path: "/eu-es/CmsPreview",
    component: _35347821,
    name: "CmsPreview___eu-es"
  }, {
    path: "/eu-es/contact",
    component: _b1da17a4,
    name: "contact-fr___eu-es"
  }, {
    path: "/eu-es/contact",
    component: _b1da17a4,
    name: "contact___eu-es"
  }, {
    path: "/eu-es/contact",
    component: _b1da17a4,
    name: "contact___eu-es"
  }, {
    path: "/eu-es/Contact",
    component: _b1da17a4,
    name: "Contact___eu-es"
  }, {
    path: "/eu-es/contacto",
    component: _b1da17a4,
    name: "contact-es___eu-es"
  }, {
    path: "/eu-es/contract",
    component: _c90f5248,
    name: "contract___eu-es"
  }, {
    path: "/eu-es/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___eu-es"
  }, {
    path: "/eu-es/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___eu-es"
  }, {
    path: "/eu-es/Home",
    component: _8f04537e,
    name: "Home___eu-es"
  }, {
    path: "/eu-es/kontakt",
    component: _b1da17a4,
    name: "contact-de___eu-es"
  }, {
    path: "/eu-es/lavorazione",
    component: _deef8500,
    name: "lavorazione___eu-es"
  }, {
    path: "/eu-es/listing-preview",
    component: _3dc44706,
    name: "listing-preview___eu-es"
  }, {
    path: "/eu-es/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___eu-es"
  }, {
    path: "/eu-es/MyAccount",
    component: _10189cef,
    name: "MyAccount___eu-es",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eu-es"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___eu-es"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eu-es"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eu-es"
    }]
  }, {
    path: "/eu-es/Page",
    component: _08b2ebf1,
    name: "Page___eu-es"
  }, {
    path: "/eu-es/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___eu-es"
  }, {
    path: "/eu-es/Playground",
    component: _71483cc6,
    name: "Playground___eu-es"
  }, {
    path: "/eu-es/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___eu-es"
  }, {
    path: "/eu-es/Product",
    component: _4bba9d7d,
    name: "Product___eu-es"
  }, {
    path: "/eu-es/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eu-es"
  }, {
    path: "/eu-es/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eu-es"
  }, {
    path: "/eu-es/Route",
    component: _34cba952,
    name: "Route___eu-es"
  }, {
    path: "/eu-es/search",
    component: _6f8b500a,
    name: "search___eu-es"
  }, {
    path: "/eu-es/Search",
    component: _6f8b500a,
    name: "Search___eu-es"
  }, {
    path: "/eu-es/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___eu-es"
  }, {
    path: "/eu-es/traitement",
    component: _deef8500,
    name: "lavorazione-fr___eu-es"
  }, {
    path: "/eu-es/uber-uns",
    component: _632e98b3,
    name: "about-de___eu-es"
  }, {
    path: "/eu-es/wishlist",
    component: _3fbd01b2,
    name: "wishlist___eu-es"
  }, {
    path: "/eu-es/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eu-es"
  }, {
    path: "/eu-fr/about",
    component: _632e98b3,
    name: "about___eu-fr"
  }, {
    path: "/eu-fr/blog",
    component: _a3ec10f8,
    name: "blog___eu-fr"
  }, {
    path: "/eu-fr/Blog",
    component: _a3ec10f8,
    name: "Blog___eu-fr"
  }, {
    path: "/eu-fr/BlogPost",
    component: _78974e44,
    name: "BlogPost___eu-fr"
  }, {
    path: "/eu-fr/cart",
    component: _544757fc,
    name: "cart___eu-fr"
  }, {
    path: "/eu-fr/Cart",
    component: _544757fc,
    name: "Cart___eu-fr"
  }, {
    path: "/eu-fr/Category",
    component: _0b2841e0,
    name: "Category___eu-fr"
  }, {
    path: "/eu-fr/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___eu-fr",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___eu-fr"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___eu-fr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___eu-fr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___eu-fr"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___eu-fr"
    }]
  }, {
    path: "/eu-fr/Checkout",
    component: _48ef6e28,
    name: "Checkout___eu-fr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eu-fr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eu-fr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eu-fr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eu-fr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eu-fr"
    }]
  }, {
    path: "/eu-fr/cms-preview",
    component: _35347821,
    name: "cms-preview___eu-fr"
  }, {
    path: "/eu-fr/CmsPreview",
    component: _35347821,
    name: "CmsPreview___eu-fr"
  }, {
    path: "/eu-fr/contact",
    component: _b1da17a4,
    name: "contact-fr___eu-fr"
  }, {
    path: "/eu-fr/contact",
    component: _b1da17a4,
    name: "contact___eu-fr"
  }, {
    path: "/eu-fr/contact",
    component: _b1da17a4,
    name: "contact___eu-fr"
  }, {
    path: "/eu-fr/Contact",
    component: _b1da17a4,
    name: "Contact___eu-fr"
  }, {
    path: "/eu-fr/contacto",
    component: _b1da17a4,
    name: "contact-es___eu-fr"
  }, {
    path: "/eu-fr/contract",
    component: _c90f5248,
    name: "contract___eu-fr"
  }, {
    path: "/eu-fr/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___eu-fr"
  }, {
    path: "/eu-fr/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___eu-fr"
  }, {
    path: "/eu-fr/Home",
    component: _8f04537e,
    name: "Home___eu-fr"
  }, {
    path: "/eu-fr/kontakt",
    component: _b1da17a4,
    name: "contact-de___eu-fr"
  }, {
    path: "/eu-fr/lavorazione",
    component: _deef8500,
    name: "lavorazione___eu-fr"
  }, {
    path: "/eu-fr/listing-preview",
    component: _3dc44706,
    name: "listing-preview___eu-fr"
  }, {
    path: "/eu-fr/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___eu-fr"
  }, {
    path: "/eu-fr/MyAccount",
    component: _10189cef,
    name: "MyAccount___eu-fr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eu-fr"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___eu-fr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eu-fr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eu-fr"
    }]
  }, {
    path: "/eu-fr/Page",
    component: _08b2ebf1,
    name: "Page___eu-fr"
  }, {
    path: "/eu-fr/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___eu-fr"
  }, {
    path: "/eu-fr/Playground",
    component: _71483cc6,
    name: "Playground___eu-fr"
  }, {
    path: "/eu-fr/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___eu-fr"
  }, {
    path: "/eu-fr/Product",
    component: _4bba9d7d,
    name: "Product___eu-fr"
  }, {
    path: "/eu-fr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eu-fr"
  }, {
    path: "/eu-fr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eu-fr"
  }, {
    path: "/eu-fr/Route",
    component: _34cba952,
    name: "Route___eu-fr"
  }, {
    path: "/eu-fr/search",
    component: _6f8b500a,
    name: "search___eu-fr"
  }, {
    path: "/eu-fr/Search",
    component: _6f8b500a,
    name: "Search___eu-fr"
  }, {
    path: "/eu-fr/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___eu-fr"
  }, {
    path: "/eu-fr/traitement",
    component: _deef8500,
    name: "lavorazione-fr___eu-fr"
  }, {
    path: "/eu-fr/uber-uns",
    component: _632e98b3,
    name: "about-de___eu-fr"
  }, {
    path: "/eu-fr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___eu-fr"
  }, {
    path: "/eu-fr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eu-fr"
  }, {
    path: "/eu-it/about",
    component: _632e98b3,
    name: "about___eu-it"
  }, {
    path: "/eu-it/blog",
    component: _a3ec10f8,
    name: "blog___eu-it"
  }, {
    path: "/eu-it/Blog",
    component: _a3ec10f8,
    name: "Blog___eu-it"
  }, {
    path: "/eu-it/BlogPost",
    component: _78974e44,
    name: "BlogPost___eu-it"
  }, {
    path: "/eu-it/cart",
    component: _544757fc,
    name: "cart___eu-it"
  }, {
    path: "/eu-it/Cart",
    component: _544757fc,
    name: "Cart___eu-it"
  }, {
    path: "/eu-it/Category",
    component: _0b2841e0,
    name: "Category___eu-it"
  }, {
    path: "/eu-it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___eu-it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___eu-it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___eu-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___eu-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___eu-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___eu-it"
    }]
  }, {
    path: "/eu-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___eu-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eu-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eu-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eu-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eu-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eu-it"
    }]
  }, {
    path: "/eu-it/cms-preview",
    component: _35347821,
    name: "cms-preview___eu-it"
  }, {
    path: "/eu-it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___eu-it"
  }, {
    path: "/eu-it/contact",
    component: _b1da17a4,
    name: "contact-fr___eu-it"
  }, {
    path: "/eu-it/contact",
    component: _b1da17a4,
    name: "contact___eu-it"
  }, {
    path: "/eu-it/contact",
    component: _b1da17a4,
    name: "contact___eu-it"
  }, {
    path: "/eu-it/Contact",
    component: _b1da17a4,
    name: "Contact___eu-it"
  }, {
    path: "/eu-it/contacto",
    component: _b1da17a4,
    name: "contact-es___eu-it"
  }, {
    path: "/eu-it/contract",
    component: _c90f5248,
    name: "contract___eu-it"
  }, {
    path: "/eu-it/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___eu-it"
  }, {
    path: "/eu-it/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___eu-it"
  }, {
    path: "/eu-it/Home",
    component: _8f04537e,
    name: "Home___eu-it"
  }, {
    path: "/eu-it/kontakt",
    component: _b1da17a4,
    name: "contact-de___eu-it"
  }, {
    path: "/eu-it/lavorazione",
    component: _deef8500,
    name: "lavorazione___eu-it"
  }, {
    path: "/eu-it/listing-preview",
    component: _3dc44706,
    name: "listing-preview___eu-it"
  }, {
    path: "/eu-it/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___eu-it"
  }, {
    path: "/eu-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___eu-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eu-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___eu-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eu-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eu-it"
    }]
  }, {
    path: "/eu-it/Page",
    component: _08b2ebf1,
    name: "Page___eu-it"
  }, {
    path: "/eu-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___eu-it"
  }, {
    path: "/eu-it/Playground",
    component: _71483cc6,
    name: "Playground___eu-it"
  }, {
    path: "/eu-it/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___eu-it"
  }, {
    path: "/eu-it/Product",
    component: _4bba9d7d,
    name: "Product___eu-it"
  }, {
    path: "/eu-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eu-it"
  }, {
    path: "/eu-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eu-it"
  }, {
    path: "/eu-it/Route",
    component: _34cba952,
    name: "Route___eu-it"
  }, {
    path: "/eu-it/search",
    component: _6f8b500a,
    name: "search___eu-it"
  }, {
    path: "/eu-it/Search",
    component: _6f8b500a,
    name: "Search___eu-it"
  }, {
    path: "/eu-it/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___eu-it"
  }, {
    path: "/eu-it/traitement",
    component: _deef8500,
    name: "lavorazione-fr___eu-it"
  }, {
    path: "/eu-it/uber-uns",
    component: _632e98b3,
    name: "about-de___eu-it"
  }, {
    path: "/eu-it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___eu-it"
  }, {
    path: "/eu-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eu-it"
  }, {
    path: "/fe-en/about",
    component: _632e98b3,
    name: "about___fe-en"
  }, {
    path: "/fe-en/blog",
    component: _a3ec10f8,
    name: "blog___fe-en"
  }, {
    path: "/fe-en/Blog",
    component: _a3ec10f8,
    name: "Blog___fe-en"
  }, {
    path: "/fe-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___fe-en"
  }, {
    path: "/fe-en/cart",
    component: _544757fc,
    name: "cart___fe-en"
  }, {
    path: "/fe-en/Cart",
    component: _544757fc,
    name: "Cart___fe-en"
  }, {
    path: "/fe-en/Category",
    component: _0b2841e0,
    name: "Category___fe-en"
  }, {
    path: "/fe-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___fe-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___fe-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___fe-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___fe-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___fe-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___fe-en"
    }]
  }, {
    path: "/fe-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___fe-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___fe-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___fe-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___fe-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___fe-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___fe-en"
    }]
  }, {
    path: "/fe-en/cms-preview",
    component: _35347821,
    name: "cms-preview___fe-en"
  }, {
    path: "/fe-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___fe-en"
  }, {
    path: "/fe-en/contact",
    component: _b1da17a4,
    name: "contact-fr___fe-en"
  }, {
    path: "/fe-en/contact",
    component: _b1da17a4,
    name: "contact___fe-en"
  }, {
    path: "/fe-en/contact",
    component: _b1da17a4,
    name: "contact___fe-en"
  }, {
    path: "/fe-en/Contact",
    component: _b1da17a4,
    name: "Contact___fe-en"
  }, {
    path: "/fe-en/contacto",
    component: _b1da17a4,
    name: "contact-es___fe-en"
  }, {
    path: "/fe-en/contract",
    component: _c90f5248,
    name: "contract___fe-en"
  }, {
    path: "/fe-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___fe-en"
  }, {
    path: "/fe-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___fe-en"
  }, {
    path: "/fe-en/Home",
    component: _8f04537e,
    name: "Home___fe-en"
  }, {
    path: "/fe-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___fe-en"
  }, {
    path: "/fe-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___fe-en"
  }, {
    path: "/fe-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___fe-en"
  }, {
    path: "/fe-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___fe-en"
  }, {
    path: "/fe-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___fe-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___fe-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___fe-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___fe-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___fe-en"
    }]
  }, {
    path: "/fe-en/Page",
    component: _08b2ebf1,
    name: "Page___fe-en"
  }, {
    path: "/fe-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___fe-en"
  }, {
    path: "/fe-en/Playground",
    component: _71483cc6,
    name: "Playground___fe-en"
  }, {
    path: "/fe-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___fe-en"
  }, {
    path: "/fe-en/Product",
    component: _4bba9d7d,
    name: "Product___fe-en"
  }, {
    path: "/fe-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___fe-en"
  }, {
    path: "/fe-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___fe-en"
  }, {
    path: "/fe-en/Route",
    component: _34cba952,
    name: "Route___fe-en"
  }, {
    path: "/fe-en/search",
    component: _6f8b500a,
    name: "search___fe-en"
  }, {
    path: "/fe-en/Search",
    component: _6f8b500a,
    name: "Search___fe-en"
  }, {
    path: "/fe-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___fe-en"
  }, {
    path: "/fe-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___fe-en"
  }, {
    path: "/fe-en/uber-uns",
    component: _632e98b3,
    name: "about-de___fe-en"
  }, {
    path: "/fe-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___fe-en"
  }, {
    path: "/fe-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___fe-en"
  }, {
    path: "/it-it/about",
    component: _632e98b3,
    name: "about___it-it"
  }, {
    path: "/it-it/blog",
    component: _a3ec10f8,
    name: "blog___it-it"
  }, {
    path: "/it-it/Blog",
    component: _a3ec10f8,
    name: "Blog___it-it"
  }, {
    path: "/it-it/BlogPost",
    component: _78974e44,
    name: "BlogPost___it-it"
  }, {
    path: "/it-it/cart",
    component: _544757fc,
    name: "cart___it-it"
  }, {
    path: "/it-it/Cart",
    component: _544757fc,
    name: "Cart___it-it"
  }, {
    path: "/it-it/Category",
    component: _0b2841e0,
    name: "Category___it-it"
  }, {
    path: "/it-it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___it-it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___it-it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___it-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___it-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___it-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___it-it"
    }]
  }, {
    path: "/it-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___it-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___it-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___it-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___it-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___it-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___it-it"
    }]
  }, {
    path: "/it-it/cms-preview",
    component: _35347821,
    name: "cms-preview___it-it"
  }, {
    path: "/it-it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___it-it"
  }, {
    path: "/it-it/contact",
    component: _b1da17a4,
    name: "contact-fr___it-it"
  }, {
    path: "/it-it/contact",
    component: _b1da17a4,
    name: "contact___it-it"
  }, {
    path: "/it-it/contact",
    component: _b1da17a4,
    name: "contact___it-it"
  }, {
    path: "/it-it/Contact",
    component: _b1da17a4,
    name: "Contact___it-it"
  }, {
    path: "/it-it/contacto",
    component: _b1da17a4,
    name: "contact-es___it-it"
  }, {
    path: "/it-it/contract",
    component: _c90f5248,
    name: "contract___it-it"
  }, {
    path: "/it-it/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___it-it"
  }, {
    path: "/it-it/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___it-it"
  }, {
    path: "/it-it/Home",
    component: _8f04537e,
    name: "Home___it-it"
  }, {
    path: "/it-it/kontakt",
    component: _b1da17a4,
    name: "contact-de___it-it"
  }, {
    path: "/it-it/lavorazione",
    component: _deef8500,
    name: "lavorazione___it-it"
  }, {
    path: "/it-it/listing-preview",
    component: _3dc44706,
    name: "listing-preview___it-it"
  }, {
    path: "/it-it/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___it-it"
  }, {
    path: "/it-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___it-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___it-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___it-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___it-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___it-it"
    }]
  }, {
    path: "/it-it/Page",
    component: _08b2ebf1,
    name: "Page___it-it"
  }, {
    path: "/it-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___it-it"
  }, {
    path: "/it-it/Playground",
    component: _71483cc6,
    name: "Playground___it-it"
  }, {
    path: "/it-it/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___it-it"
  }, {
    path: "/it-it/Product",
    component: _4bba9d7d,
    name: "Product___it-it"
  }, {
    path: "/it-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___it-it"
  }, {
    path: "/it-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___it-it"
  }, {
    path: "/it-it/Route",
    component: _34cba952,
    name: "Route___it-it"
  }, {
    path: "/it-it/search",
    component: _6f8b500a,
    name: "search___it-it"
  }, {
    path: "/it-it/Search",
    component: _6f8b500a,
    name: "Search___it-it"
  }, {
    path: "/it-it/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___it-it"
  }, {
    path: "/it-it/traitement",
    component: _deef8500,
    name: "lavorazione-fr___it-it"
  }, {
    path: "/it-it/uber-uns",
    component: _632e98b3,
    name: "about-de___it-it"
  }, {
    path: "/it-it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___it-it"
  }, {
    path: "/it-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___it-it"
  }, {
    path: "/jp-de/about",
    component: _632e98b3,
    name: "about___jp-de"
  }, {
    path: "/jp-de/blog",
    component: _a3ec10f8,
    name: "blog___jp-de"
  }, {
    path: "/jp-de/Blog",
    component: _a3ec10f8,
    name: "Blog___jp-de"
  }, {
    path: "/jp-de/BlogPost",
    component: _78974e44,
    name: "BlogPost___jp-de"
  }, {
    path: "/jp-de/cart",
    component: _544757fc,
    name: "cart___jp-de"
  }, {
    path: "/jp-de/Cart",
    component: _544757fc,
    name: "Cart___jp-de"
  }, {
    path: "/jp-de/Category",
    component: _0b2841e0,
    name: "Category___jp-de"
  }, {
    path: "/jp-de/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___jp-de",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___jp-de"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___jp-de"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___jp-de"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___jp-de"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___jp-de"
    }]
  }, {
    path: "/jp-de/Checkout",
    component: _48ef6e28,
    name: "Checkout___jp-de",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___jp-de"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___jp-de"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___jp-de"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___jp-de"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___jp-de"
    }]
  }, {
    path: "/jp-de/cms-preview",
    component: _35347821,
    name: "cms-preview___jp-de"
  }, {
    path: "/jp-de/CmsPreview",
    component: _35347821,
    name: "CmsPreview___jp-de"
  }, {
    path: "/jp-de/contact",
    component: _b1da17a4,
    name: "contact-fr___jp-de"
  }, {
    path: "/jp-de/contact",
    component: _b1da17a4,
    name: "contact___jp-de"
  }, {
    path: "/jp-de/contact",
    component: _b1da17a4,
    name: "contact___jp-de"
  }, {
    path: "/jp-de/Contact",
    component: _b1da17a4,
    name: "Contact___jp-de"
  }, {
    path: "/jp-de/contacto",
    component: _b1da17a4,
    name: "contact-es___jp-de"
  }, {
    path: "/jp-de/contract",
    component: _c90f5248,
    name: "contract___jp-de"
  }, {
    path: "/jp-de/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___jp-de"
  }, {
    path: "/jp-de/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___jp-de"
  }, {
    path: "/jp-de/Home",
    component: _8f04537e,
    name: "Home___jp-de"
  }, {
    path: "/jp-de/kontakt",
    component: _b1da17a4,
    name: "contact-de___jp-de"
  }, {
    path: "/jp-de/lavorazione",
    component: _deef8500,
    name: "lavorazione___jp-de"
  }, {
    path: "/jp-de/listing-preview",
    component: _3dc44706,
    name: "listing-preview___jp-de"
  }, {
    path: "/jp-de/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___jp-de"
  }, {
    path: "/jp-de/MyAccount",
    component: _10189cef,
    name: "MyAccount___jp-de",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___jp-de"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___jp-de"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___jp-de"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___jp-de"
    }]
  }, {
    path: "/jp-de/Page",
    component: _08b2ebf1,
    name: "Page___jp-de"
  }, {
    path: "/jp-de/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___jp-de"
  }, {
    path: "/jp-de/Playground",
    component: _71483cc6,
    name: "Playground___jp-de"
  }, {
    path: "/jp-de/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___jp-de"
  }, {
    path: "/jp-de/Product",
    component: _4bba9d7d,
    name: "Product___jp-de"
  }, {
    path: "/jp-de/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___jp-de"
  }, {
    path: "/jp-de/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___jp-de"
  }, {
    path: "/jp-de/Route",
    component: _34cba952,
    name: "Route___jp-de"
  }, {
    path: "/jp-de/search",
    component: _6f8b500a,
    name: "search___jp-de"
  }, {
    path: "/jp-de/Search",
    component: _6f8b500a,
    name: "Search___jp-de"
  }, {
    path: "/jp-de/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___jp-de"
  }, {
    path: "/jp-de/traitement",
    component: _deef8500,
    name: "lavorazione-fr___jp-de"
  }, {
    path: "/jp-de/uber-uns",
    component: _632e98b3,
    name: "about-de___jp-de"
  }, {
    path: "/jp-de/wishlist",
    component: _3fbd01b2,
    name: "wishlist___jp-de"
  }, {
    path: "/jp-de/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___jp-de"
  }, {
    path: "/jp-en/about",
    component: _632e98b3,
    name: "about___jp-en"
  }, {
    path: "/jp-en/blog",
    component: _a3ec10f8,
    name: "blog___jp-en"
  }, {
    path: "/jp-en/Blog",
    component: _a3ec10f8,
    name: "Blog___jp-en"
  }, {
    path: "/jp-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___jp-en"
  }, {
    path: "/jp-en/cart",
    component: _544757fc,
    name: "cart___jp-en"
  }, {
    path: "/jp-en/Cart",
    component: _544757fc,
    name: "Cart___jp-en"
  }, {
    path: "/jp-en/Category",
    component: _0b2841e0,
    name: "Category___jp-en"
  }, {
    path: "/jp-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___jp-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___jp-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___jp-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___jp-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___jp-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___jp-en"
    }]
  }, {
    path: "/jp-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___jp-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___jp-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___jp-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___jp-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___jp-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___jp-en"
    }]
  }, {
    path: "/jp-en/cms-preview",
    component: _35347821,
    name: "cms-preview___jp-en"
  }, {
    path: "/jp-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___jp-en"
  }, {
    path: "/jp-en/contact",
    component: _b1da17a4,
    name: "contact-fr___jp-en"
  }, {
    path: "/jp-en/contact",
    component: _b1da17a4,
    name: "contact___jp-en"
  }, {
    path: "/jp-en/contact",
    component: _b1da17a4,
    name: "contact___jp-en"
  }, {
    path: "/jp-en/Contact",
    component: _b1da17a4,
    name: "Contact___jp-en"
  }, {
    path: "/jp-en/contacto",
    component: _b1da17a4,
    name: "contact-es___jp-en"
  }, {
    path: "/jp-en/contract",
    component: _c90f5248,
    name: "contract___jp-en"
  }, {
    path: "/jp-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___jp-en"
  }, {
    path: "/jp-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___jp-en"
  }, {
    path: "/jp-en/Home",
    component: _8f04537e,
    name: "Home___jp-en"
  }, {
    path: "/jp-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___jp-en"
  }, {
    path: "/jp-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___jp-en"
  }, {
    path: "/jp-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___jp-en"
  }, {
    path: "/jp-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___jp-en"
  }, {
    path: "/jp-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___jp-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___jp-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___jp-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___jp-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___jp-en"
    }]
  }, {
    path: "/jp-en/Page",
    component: _08b2ebf1,
    name: "Page___jp-en"
  }, {
    path: "/jp-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___jp-en"
  }, {
    path: "/jp-en/Playground",
    component: _71483cc6,
    name: "Playground___jp-en"
  }, {
    path: "/jp-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___jp-en"
  }, {
    path: "/jp-en/Product",
    component: _4bba9d7d,
    name: "Product___jp-en"
  }, {
    path: "/jp-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___jp-en"
  }, {
    path: "/jp-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___jp-en"
  }, {
    path: "/jp-en/Route",
    component: _34cba952,
    name: "Route___jp-en"
  }, {
    path: "/jp-en/search",
    component: _6f8b500a,
    name: "search___jp-en"
  }, {
    path: "/jp-en/Search",
    component: _6f8b500a,
    name: "Search___jp-en"
  }, {
    path: "/jp-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___jp-en"
  }, {
    path: "/jp-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___jp-en"
  }, {
    path: "/jp-en/uber-uns",
    component: _632e98b3,
    name: "about-de___jp-en"
  }, {
    path: "/jp-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___jp-en"
  }, {
    path: "/jp-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___jp-en"
  }, {
    path: "/jp-es/about",
    component: _632e98b3,
    name: "about___jp-es"
  }, {
    path: "/jp-es/blog",
    component: _a3ec10f8,
    name: "blog___jp-es"
  }, {
    path: "/jp-es/Blog",
    component: _a3ec10f8,
    name: "Blog___jp-es"
  }, {
    path: "/jp-es/BlogPost",
    component: _78974e44,
    name: "BlogPost___jp-es"
  }, {
    path: "/jp-es/cart",
    component: _544757fc,
    name: "cart___jp-es"
  }, {
    path: "/jp-es/Cart",
    component: _544757fc,
    name: "Cart___jp-es"
  }, {
    path: "/jp-es/Category",
    component: _0b2841e0,
    name: "Category___jp-es"
  }, {
    path: "/jp-es/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___jp-es",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___jp-es"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___jp-es"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___jp-es"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___jp-es"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___jp-es"
    }]
  }, {
    path: "/jp-es/Checkout",
    component: _48ef6e28,
    name: "Checkout___jp-es",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___jp-es"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___jp-es"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___jp-es"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___jp-es"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___jp-es"
    }]
  }, {
    path: "/jp-es/cms-preview",
    component: _35347821,
    name: "cms-preview___jp-es"
  }, {
    path: "/jp-es/CmsPreview",
    component: _35347821,
    name: "CmsPreview___jp-es"
  }, {
    path: "/jp-es/contact",
    component: _b1da17a4,
    name: "contact-fr___jp-es"
  }, {
    path: "/jp-es/contact",
    component: _b1da17a4,
    name: "contact___jp-es"
  }, {
    path: "/jp-es/contact",
    component: _b1da17a4,
    name: "contact___jp-es"
  }, {
    path: "/jp-es/Contact",
    component: _b1da17a4,
    name: "Contact___jp-es"
  }, {
    path: "/jp-es/contacto",
    component: _b1da17a4,
    name: "contact-es___jp-es"
  }, {
    path: "/jp-es/contract",
    component: _c90f5248,
    name: "contract___jp-es"
  }, {
    path: "/jp-es/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___jp-es"
  }, {
    path: "/jp-es/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___jp-es"
  }, {
    path: "/jp-es/Home",
    component: _8f04537e,
    name: "Home___jp-es"
  }, {
    path: "/jp-es/kontakt",
    component: _b1da17a4,
    name: "contact-de___jp-es"
  }, {
    path: "/jp-es/lavorazione",
    component: _deef8500,
    name: "lavorazione___jp-es"
  }, {
    path: "/jp-es/listing-preview",
    component: _3dc44706,
    name: "listing-preview___jp-es"
  }, {
    path: "/jp-es/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___jp-es"
  }, {
    path: "/jp-es/MyAccount",
    component: _10189cef,
    name: "MyAccount___jp-es",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___jp-es"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___jp-es"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___jp-es"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___jp-es"
    }]
  }, {
    path: "/jp-es/Page",
    component: _08b2ebf1,
    name: "Page___jp-es"
  }, {
    path: "/jp-es/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___jp-es"
  }, {
    path: "/jp-es/Playground",
    component: _71483cc6,
    name: "Playground___jp-es"
  }, {
    path: "/jp-es/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___jp-es"
  }, {
    path: "/jp-es/Product",
    component: _4bba9d7d,
    name: "Product___jp-es"
  }, {
    path: "/jp-es/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___jp-es"
  }, {
    path: "/jp-es/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___jp-es"
  }, {
    path: "/jp-es/Route",
    component: _34cba952,
    name: "Route___jp-es"
  }, {
    path: "/jp-es/search",
    component: _6f8b500a,
    name: "search___jp-es"
  }, {
    path: "/jp-es/Search",
    component: _6f8b500a,
    name: "Search___jp-es"
  }, {
    path: "/jp-es/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___jp-es"
  }, {
    path: "/jp-es/traitement",
    component: _deef8500,
    name: "lavorazione-fr___jp-es"
  }, {
    path: "/jp-es/uber-uns",
    component: _632e98b3,
    name: "about-de___jp-es"
  }, {
    path: "/jp-es/wishlist",
    component: _3fbd01b2,
    name: "wishlist___jp-es"
  }, {
    path: "/jp-es/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___jp-es"
  }, {
    path: "/jp-fr/about",
    component: _632e98b3,
    name: "about___jp-fr"
  }, {
    path: "/jp-fr/blog",
    component: _a3ec10f8,
    name: "blog___jp-fr"
  }, {
    path: "/jp-fr/Blog",
    component: _a3ec10f8,
    name: "Blog___jp-fr"
  }, {
    path: "/jp-fr/BlogPost",
    component: _78974e44,
    name: "BlogPost___jp-fr"
  }, {
    path: "/jp-fr/cart",
    component: _544757fc,
    name: "cart___jp-fr"
  }, {
    path: "/jp-fr/Cart",
    component: _544757fc,
    name: "Cart___jp-fr"
  }, {
    path: "/jp-fr/Category",
    component: _0b2841e0,
    name: "Category___jp-fr"
  }, {
    path: "/jp-fr/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___jp-fr",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___jp-fr"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___jp-fr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___jp-fr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___jp-fr"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___jp-fr"
    }]
  }, {
    path: "/jp-fr/Checkout",
    component: _48ef6e28,
    name: "Checkout___jp-fr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___jp-fr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___jp-fr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___jp-fr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___jp-fr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___jp-fr"
    }]
  }, {
    path: "/jp-fr/cms-preview",
    component: _35347821,
    name: "cms-preview___jp-fr"
  }, {
    path: "/jp-fr/CmsPreview",
    component: _35347821,
    name: "CmsPreview___jp-fr"
  }, {
    path: "/jp-fr/contact",
    component: _b1da17a4,
    name: "contact-fr___jp-fr"
  }, {
    path: "/jp-fr/contact",
    component: _b1da17a4,
    name: "contact___jp-fr"
  }, {
    path: "/jp-fr/contact",
    component: _b1da17a4,
    name: "contact___jp-fr"
  }, {
    path: "/jp-fr/Contact",
    component: _b1da17a4,
    name: "Contact___jp-fr"
  }, {
    path: "/jp-fr/contacto",
    component: _b1da17a4,
    name: "contact-es___jp-fr"
  }, {
    path: "/jp-fr/contract",
    component: _c90f5248,
    name: "contract___jp-fr"
  }, {
    path: "/jp-fr/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___jp-fr"
  }, {
    path: "/jp-fr/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___jp-fr"
  }, {
    path: "/jp-fr/Home",
    component: _8f04537e,
    name: "Home___jp-fr"
  }, {
    path: "/jp-fr/kontakt",
    component: _b1da17a4,
    name: "contact-de___jp-fr"
  }, {
    path: "/jp-fr/lavorazione",
    component: _deef8500,
    name: "lavorazione___jp-fr"
  }, {
    path: "/jp-fr/listing-preview",
    component: _3dc44706,
    name: "listing-preview___jp-fr"
  }, {
    path: "/jp-fr/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___jp-fr"
  }, {
    path: "/jp-fr/MyAccount",
    component: _10189cef,
    name: "MyAccount___jp-fr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___jp-fr"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___jp-fr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___jp-fr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___jp-fr"
    }]
  }, {
    path: "/jp-fr/Page",
    component: _08b2ebf1,
    name: "Page___jp-fr"
  }, {
    path: "/jp-fr/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___jp-fr"
  }, {
    path: "/jp-fr/Playground",
    component: _71483cc6,
    name: "Playground___jp-fr"
  }, {
    path: "/jp-fr/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___jp-fr"
  }, {
    path: "/jp-fr/Product",
    component: _4bba9d7d,
    name: "Product___jp-fr"
  }, {
    path: "/jp-fr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___jp-fr"
  }, {
    path: "/jp-fr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___jp-fr"
  }, {
    path: "/jp-fr/Route",
    component: _34cba952,
    name: "Route___jp-fr"
  }, {
    path: "/jp-fr/search",
    component: _6f8b500a,
    name: "search___jp-fr"
  }, {
    path: "/jp-fr/Search",
    component: _6f8b500a,
    name: "Search___jp-fr"
  }, {
    path: "/jp-fr/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___jp-fr"
  }, {
    path: "/jp-fr/traitement",
    component: _deef8500,
    name: "lavorazione-fr___jp-fr"
  }, {
    path: "/jp-fr/uber-uns",
    component: _632e98b3,
    name: "about-de___jp-fr"
  }, {
    path: "/jp-fr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___jp-fr"
  }, {
    path: "/jp-fr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___jp-fr"
  }, {
    path: "/jp-it/about",
    component: _632e98b3,
    name: "about___jp-it"
  }, {
    path: "/jp-it/blog",
    component: _a3ec10f8,
    name: "blog___jp-it"
  }, {
    path: "/jp-it/Blog",
    component: _a3ec10f8,
    name: "Blog___jp-it"
  }, {
    path: "/jp-it/BlogPost",
    component: _78974e44,
    name: "BlogPost___jp-it"
  }, {
    path: "/jp-it/cart",
    component: _544757fc,
    name: "cart___jp-it"
  }, {
    path: "/jp-it/Cart",
    component: _544757fc,
    name: "Cart___jp-it"
  }, {
    path: "/jp-it/Category",
    component: _0b2841e0,
    name: "Category___jp-it"
  }, {
    path: "/jp-it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___jp-it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___jp-it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___jp-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___jp-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___jp-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___jp-it"
    }]
  }, {
    path: "/jp-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___jp-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___jp-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___jp-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___jp-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___jp-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___jp-it"
    }]
  }, {
    path: "/jp-it/cms-preview",
    component: _35347821,
    name: "cms-preview___jp-it"
  }, {
    path: "/jp-it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___jp-it"
  }, {
    path: "/jp-it/contact",
    component: _b1da17a4,
    name: "contact-fr___jp-it"
  }, {
    path: "/jp-it/contact",
    component: _b1da17a4,
    name: "contact___jp-it"
  }, {
    path: "/jp-it/contact",
    component: _b1da17a4,
    name: "contact___jp-it"
  }, {
    path: "/jp-it/Contact",
    component: _b1da17a4,
    name: "Contact___jp-it"
  }, {
    path: "/jp-it/contacto",
    component: _b1da17a4,
    name: "contact-es___jp-it"
  }, {
    path: "/jp-it/contract",
    component: _c90f5248,
    name: "contract___jp-it"
  }, {
    path: "/jp-it/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___jp-it"
  }, {
    path: "/jp-it/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___jp-it"
  }, {
    path: "/jp-it/Home",
    component: _8f04537e,
    name: "Home___jp-it"
  }, {
    path: "/jp-it/kontakt",
    component: _b1da17a4,
    name: "contact-de___jp-it"
  }, {
    path: "/jp-it/lavorazione",
    component: _deef8500,
    name: "lavorazione___jp-it"
  }, {
    path: "/jp-it/listing-preview",
    component: _3dc44706,
    name: "listing-preview___jp-it"
  }, {
    path: "/jp-it/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___jp-it"
  }, {
    path: "/jp-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___jp-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___jp-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___jp-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___jp-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___jp-it"
    }]
  }, {
    path: "/jp-it/Page",
    component: _08b2ebf1,
    name: "Page___jp-it"
  }, {
    path: "/jp-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___jp-it"
  }, {
    path: "/jp-it/Playground",
    component: _71483cc6,
    name: "Playground___jp-it"
  }, {
    path: "/jp-it/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___jp-it"
  }, {
    path: "/jp-it/Product",
    component: _4bba9d7d,
    name: "Product___jp-it"
  }, {
    path: "/jp-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___jp-it"
  }, {
    path: "/jp-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___jp-it"
  }, {
    path: "/jp-it/Route",
    component: _34cba952,
    name: "Route___jp-it"
  }, {
    path: "/jp-it/search",
    component: _6f8b500a,
    name: "search___jp-it"
  }, {
    path: "/jp-it/Search",
    component: _6f8b500a,
    name: "Search___jp-it"
  }, {
    path: "/jp-it/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___jp-it"
  }, {
    path: "/jp-it/traitement",
    component: _deef8500,
    name: "lavorazione-fr___jp-it"
  }, {
    path: "/jp-it/uber-uns",
    component: _632e98b3,
    name: "about-de___jp-it"
  }, {
    path: "/jp-it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___jp-it"
  }, {
    path: "/jp-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___jp-it"
  }, {
    path: "/me-en/about",
    component: _632e98b3,
    name: "about___me-en"
  }, {
    path: "/me-en/blog",
    component: _a3ec10f8,
    name: "blog___me-en"
  }, {
    path: "/me-en/Blog",
    component: _a3ec10f8,
    name: "Blog___me-en"
  }, {
    path: "/me-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___me-en"
  }, {
    path: "/me-en/cart",
    component: _544757fc,
    name: "cart___me-en"
  }, {
    path: "/me-en/Cart",
    component: _544757fc,
    name: "Cart___me-en"
  }, {
    path: "/me-en/Category",
    component: _0b2841e0,
    name: "Category___me-en"
  }, {
    path: "/me-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___me-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___me-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___me-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___me-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___me-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___me-en"
    }]
  }, {
    path: "/me-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___me-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___me-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___me-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___me-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___me-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___me-en"
    }]
  }, {
    path: "/me-en/cms-preview",
    component: _35347821,
    name: "cms-preview___me-en"
  }, {
    path: "/me-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___me-en"
  }, {
    path: "/me-en/contact",
    component: _b1da17a4,
    name: "contact-fr___me-en"
  }, {
    path: "/me-en/contact",
    component: _b1da17a4,
    name: "contact___me-en"
  }, {
    path: "/me-en/contact",
    component: _b1da17a4,
    name: "contact___me-en"
  }, {
    path: "/me-en/Contact",
    component: _b1da17a4,
    name: "Contact___me-en"
  }, {
    path: "/me-en/contacto",
    component: _b1da17a4,
    name: "contact-es___me-en"
  }, {
    path: "/me-en/contract",
    component: _c90f5248,
    name: "contract___me-en"
  }, {
    path: "/me-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___me-en"
  }, {
    path: "/me-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___me-en"
  }, {
    path: "/me-en/Home",
    component: _8f04537e,
    name: "Home___me-en"
  }, {
    path: "/me-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___me-en"
  }, {
    path: "/me-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___me-en"
  }, {
    path: "/me-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___me-en"
  }, {
    path: "/me-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___me-en"
  }, {
    path: "/me-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___me-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___me-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___me-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___me-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___me-en"
    }]
  }, {
    path: "/me-en/Page",
    component: _08b2ebf1,
    name: "Page___me-en"
  }, {
    path: "/me-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___me-en"
  }, {
    path: "/me-en/Playground",
    component: _71483cc6,
    name: "Playground___me-en"
  }, {
    path: "/me-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___me-en"
  }, {
    path: "/me-en/Product",
    component: _4bba9d7d,
    name: "Product___me-en"
  }, {
    path: "/me-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___me-en"
  }, {
    path: "/me-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___me-en"
  }, {
    path: "/me-en/Route",
    component: _34cba952,
    name: "Route___me-en"
  }, {
    path: "/me-en/search",
    component: _6f8b500a,
    name: "search___me-en"
  }, {
    path: "/me-en/Search",
    component: _6f8b500a,
    name: "Search___me-en"
  }, {
    path: "/me-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___me-en"
  }, {
    path: "/me-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___me-en"
  }, {
    path: "/me-en/uber-uns",
    component: _632e98b3,
    name: "about-de___me-en"
  }, {
    path: "/me-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___me-en"
  }, {
    path: "/me-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___me-en"
  }, {
    path: "/row-de/about",
    component: _632e98b3,
    name: "about___row-de"
  }, {
    path: "/row-de/blog",
    component: _a3ec10f8,
    name: "blog___row-de"
  }, {
    path: "/row-de/Blog",
    component: _a3ec10f8,
    name: "Blog___row-de"
  }, {
    path: "/row-de/BlogPost",
    component: _78974e44,
    name: "BlogPost___row-de"
  }, {
    path: "/row-de/cart",
    component: _544757fc,
    name: "cart___row-de"
  }, {
    path: "/row-de/Cart",
    component: _544757fc,
    name: "Cart___row-de"
  }, {
    path: "/row-de/Category",
    component: _0b2841e0,
    name: "Category___row-de"
  }, {
    path: "/row-de/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___row-de",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___row-de"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___row-de"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___row-de"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___row-de"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___row-de"
    }]
  }, {
    path: "/row-de/Checkout",
    component: _48ef6e28,
    name: "Checkout___row-de",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___row-de"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___row-de"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___row-de"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___row-de"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___row-de"
    }]
  }, {
    path: "/row-de/cms-preview",
    component: _35347821,
    name: "cms-preview___row-de"
  }, {
    path: "/row-de/CmsPreview",
    component: _35347821,
    name: "CmsPreview___row-de"
  }, {
    path: "/row-de/contact",
    component: _b1da17a4,
    name: "contact-fr___row-de"
  }, {
    path: "/row-de/contact",
    component: _b1da17a4,
    name: "contact___row-de"
  }, {
    path: "/row-de/contact",
    component: _b1da17a4,
    name: "contact___row-de"
  }, {
    path: "/row-de/Contact",
    component: _b1da17a4,
    name: "Contact___row-de"
  }, {
    path: "/row-de/contacto",
    component: _b1da17a4,
    name: "contact-es___row-de"
  }, {
    path: "/row-de/contract",
    component: _c90f5248,
    name: "contract___row-de"
  }, {
    path: "/row-de/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___row-de"
  }, {
    path: "/row-de/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___row-de"
  }, {
    path: "/row-de/Home",
    component: _8f04537e,
    name: "Home___row-de"
  }, {
    path: "/row-de/kontakt",
    component: _b1da17a4,
    name: "contact-de___row-de"
  }, {
    path: "/row-de/lavorazione",
    component: _deef8500,
    name: "lavorazione___row-de"
  }, {
    path: "/row-de/listing-preview",
    component: _3dc44706,
    name: "listing-preview___row-de"
  }, {
    path: "/row-de/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___row-de"
  }, {
    path: "/row-de/MyAccount",
    component: _10189cef,
    name: "MyAccount___row-de",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___row-de"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___row-de"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___row-de"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___row-de"
    }]
  }, {
    path: "/row-de/Page",
    component: _08b2ebf1,
    name: "Page___row-de"
  }, {
    path: "/row-de/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___row-de"
  }, {
    path: "/row-de/Playground",
    component: _71483cc6,
    name: "Playground___row-de"
  }, {
    path: "/row-de/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___row-de"
  }, {
    path: "/row-de/Product",
    component: _4bba9d7d,
    name: "Product___row-de"
  }, {
    path: "/row-de/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___row-de"
  }, {
    path: "/row-de/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___row-de"
  }, {
    path: "/row-de/Route",
    component: _34cba952,
    name: "Route___row-de"
  }, {
    path: "/row-de/search",
    component: _6f8b500a,
    name: "search___row-de"
  }, {
    path: "/row-de/Search",
    component: _6f8b500a,
    name: "Search___row-de"
  }, {
    path: "/row-de/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___row-de"
  }, {
    path: "/row-de/traitement",
    component: _deef8500,
    name: "lavorazione-fr___row-de"
  }, {
    path: "/row-de/uber-uns",
    component: _632e98b3,
    name: "about-de___row-de"
  }, {
    path: "/row-de/wishlist",
    component: _3fbd01b2,
    name: "wishlist___row-de"
  }, {
    path: "/row-de/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___row-de"
  }, {
    path: "/row-en/about",
    component: _632e98b3,
    name: "about___row-en"
  }, {
    path: "/row-en/blog",
    component: _a3ec10f8,
    name: "blog___row-en"
  }, {
    path: "/row-en/Blog",
    component: _a3ec10f8,
    name: "Blog___row-en"
  }, {
    path: "/row-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___row-en"
  }, {
    path: "/row-en/cart",
    component: _544757fc,
    name: "cart___row-en"
  }, {
    path: "/row-en/Cart",
    component: _544757fc,
    name: "Cart___row-en"
  }, {
    path: "/row-en/Category",
    component: _0b2841e0,
    name: "Category___row-en"
  }, {
    path: "/row-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___row-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___row-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___row-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___row-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___row-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___row-en"
    }]
  }, {
    path: "/row-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___row-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___row-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___row-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___row-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___row-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___row-en"
    }]
  }, {
    path: "/row-en/cms-preview",
    component: _35347821,
    name: "cms-preview___row-en"
  }, {
    path: "/row-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___row-en"
  }, {
    path: "/row-en/contact",
    component: _b1da17a4,
    name: "contact-fr___row-en"
  }, {
    path: "/row-en/contact",
    component: _b1da17a4,
    name: "contact___row-en"
  }, {
    path: "/row-en/contact",
    component: _b1da17a4,
    name: "contact___row-en"
  }, {
    path: "/row-en/Contact",
    component: _b1da17a4,
    name: "Contact___row-en"
  }, {
    path: "/row-en/contacto",
    component: _b1da17a4,
    name: "contact-es___row-en"
  }, {
    path: "/row-en/contract",
    component: _c90f5248,
    name: "contract___row-en"
  }, {
    path: "/row-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___row-en"
  }, {
    path: "/row-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___row-en"
  }, {
    path: "/row-en/Home",
    component: _8f04537e,
    name: "Home___row-en"
  }, {
    path: "/row-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___row-en"
  }, {
    path: "/row-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___row-en"
  }, {
    path: "/row-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___row-en"
  }, {
    path: "/row-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___row-en"
  }, {
    path: "/row-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___row-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___row-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___row-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___row-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___row-en"
    }]
  }, {
    path: "/row-en/Page",
    component: _08b2ebf1,
    name: "Page___row-en"
  }, {
    path: "/row-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___row-en"
  }, {
    path: "/row-en/Playground",
    component: _71483cc6,
    name: "Playground___row-en"
  }, {
    path: "/row-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___row-en"
  }, {
    path: "/row-en/Product",
    component: _4bba9d7d,
    name: "Product___row-en"
  }, {
    path: "/row-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___row-en"
  }, {
    path: "/row-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___row-en"
  }, {
    path: "/row-en/Route",
    component: _34cba952,
    name: "Route___row-en"
  }, {
    path: "/row-en/search",
    component: _6f8b500a,
    name: "search___row-en"
  }, {
    path: "/row-en/Search",
    component: _6f8b500a,
    name: "Search___row-en"
  }, {
    path: "/row-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___row-en"
  }, {
    path: "/row-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___row-en"
  }, {
    path: "/row-en/uber-uns",
    component: _632e98b3,
    name: "about-de___row-en"
  }, {
    path: "/row-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___row-en"
  }, {
    path: "/row-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___row-en"
  }, {
    path: "/row-es/about",
    component: _632e98b3,
    name: "about___row-es"
  }, {
    path: "/row-es/blog",
    component: _a3ec10f8,
    name: "blog___row-es"
  }, {
    path: "/row-es/Blog",
    component: _a3ec10f8,
    name: "Blog___row-es"
  }, {
    path: "/row-es/BlogPost",
    component: _78974e44,
    name: "BlogPost___row-es"
  }, {
    path: "/row-es/cart",
    component: _544757fc,
    name: "cart___row-es"
  }, {
    path: "/row-es/Cart",
    component: _544757fc,
    name: "Cart___row-es"
  }, {
    path: "/row-es/Category",
    component: _0b2841e0,
    name: "Category___row-es"
  }, {
    path: "/row-es/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___row-es",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___row-es"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___row-es"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___row-es"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___row-es"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___row-es"
    }]
  }, {
    path: "/row-es/Checkout",
    component: _48ef6e28,
    name: "Checkout___row-es",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___row-es"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___row-es"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___row-es"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___row-es"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___row-es"
    }]
  }, {
    path: "/row-es/cms-preview",
    component: _35347821,
    name: "cms-preview___row-es"
  }, {
    path: "/row-es/CmsPreview",
    component: _35347821,
    name: "CmsPreview___row-es"
  }, {
    path: "/row-es/contact",
    component: _b1da17a4,
    name: "contact-fr___row-es"
  }, {
    path: "/row-es/contact",
    component: _b1da17a4,
    name: "contact___row-es"
  }, {
    path: "/row-es/contact",
    component: _b1da17a4,
    name: "contact___row-es"
  }, {
    path: "/row-es/Contact",
    component: _b1da17a4,
    name: "Contact___row-es"
  }, {
    path: "/row-es/contacto",
    component: _b1da17a4,
    name: "contact-es___row-es"
  }, {
    path: "/row-es/contract",
    component: _c90f5248,
    name: "contract___row-es"
  }, {
    path: "/row-es/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___row-es"
  }, {
    path: "/row-es/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___row-es"
  }, {
    path: "/row-es/Home",
    component: _8f04537e,
    name: "Home___row-es"
  }, {
    path: "/row-es/kontakt",
    component: _b1da17a4,
    name: "contact-de___row-es"
  }, {
    path: "/row-es/lavorazione",
    component: _deef8500,
    name: "lavorazione___row-es"
  }, {
    path: "/row-es/listing-preview",
    component: _3dc44706,
    name: "listing-preview___row-es"
  }, {
    path: "/row-es/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___row-es"
  }, {
    path: "/row-es/MyAccount",
    component: _10189cef,
    name: "MyAccount___row-es",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___row-es"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___row-es"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___row-es"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___row-es"
    }]
  }, {
    path: "/row-es/Page",
    component: _08b2ebf1,
    name: "Page___row-es"
  }, {
    path: "/row-es/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___row-es"
  }, {
    path: "/row-es/Playground",
    component: _71483cc6,
    name: "Playground___row-es"
  }, {
    path: "/row-es/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___row-es"
  }, {
    path: "/row-es/Product",
    component: _4bba9d7d,
    name: "Product___row-es"
  }, {
    path: "/row-es/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___row-es"
  }, {
    path: "/row-es/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___row-es"
  }, {
    path: "/row-es/Route",
    component: _34cba952,
    name: "Route___row-es"
  }, {
    path: "/row-es/search",
    component: _6f8b500a,
    name: "search___row-es"
  }, {
    path: "/row-es/Search",
    component: _6f8b500a,
    name: "Search___row-es"
  }, {
    path: "/row-es/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___row-es"
  }, {
    path: "/row-es/traitement",
    component: _deef8500,
    name: "lavorazione-fr___row-es"
  }, {
    path: "/row-es/uber-uns",
    component: _632e98b3,
    name: "about-de___row-es"
  }, {
    path: "/row-es/wishlist",
    component: _3fbd01b2,
    name: "wishlist___row-es"
  }, {
    path: "/row-es/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___row-es"
  }, {
    path: "/row-fr/about",
    component: _632e98b3,
    name: "about___row-fr"
  }, {
    path: "/row-fr/blog",
    component: _a3ec10f8,
    name: "blog___row-fr"
  }, {
    path: "/row-fr/Blog",
    component: _a3ec10f8,
    name: "Blog___row-fr"
  }, {
    path: "/row-fr/BlogPost",
    component: _78974e44,
    name: "BlogPost___row-fr"
  }, {
    path: "/row-fr/cart",
    component: _544757fc,
    name: "cart___row-fr"
  }, {
    path: "/row-fr/Cart",
    component: _544757fc,
    name: "Cart___row-fr"
  }, {
    path: "/row-fr/Category",
    component: _0b2841e0,
    name: "Category___row-fr"
  }, {
    path: "/row-fr/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___row-fr",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___row-fr"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___row-fr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___row-fr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___row-fr"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___row-fr"
    }]
  }, {
    path: "/row-fr/Checkout",
    component: _48ef6e28,
    name: "Checkout___row-fr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___row-fr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___row-fr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___row-fr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___row-fr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___row-fr"
    }]
  }, {
    path: "/row-fr/cms-preview",
    component: _35347821,
    name: "cms-preview___row-fr"
  }, {
    path: "/row-fr/CmsPreview",
    component: _35347821,
    name: "CmsPreview___row-fr"
  }, {
    path: "/row-fr/contact",
    component: _b1da17a4,
    name: "contact-fr___row-fr"
  }, {
    path: "/row-fr/contact",
    component: _b1da17a4,
    name: "contact___row-fr"
  }, {
    path: "/row-fr/contact",
    component: _b1da17a4,
    name: "contact___row-fr"
  }, {
    path: "/row-fr/Contact",
    component: _b1da17a4,
    name: "Contact___row-fr"
  }, {
    path: "/row-fr/contacto",
    component: _b1da17a4,
    name: "contact-es___row-fr"
  }, {
    path: "/row-fr/contract",
    component: _c90f5248,
    name: "contract___row-fr"
  }, {
    path: "/row-fr/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___row-fr"
  }, {
    path: "/row-fr/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___row-fr"
  }, {
    path: "/row-fr/Home",
    component: _8f04537e,
    name: "Home___row-fr"
  }, {
    path: "/row-fr/kontakt",
    component: _b1da17a4,
    name: "contact-de___row-fr"
  }, {
    path: "/row-fr/lavorazione",
    component: _deef8500,
    name: "lavorazione___row-fr"
  }, {
    path: "/row-fr/listing-preview",
    component: _3dc44706,
    name: "listing-preview___row-fr"
  }, {
    path: "/row-fr/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___row-fr"
  }, {
    path: "/row-fr/MyAccount",
    component: _10189cef,
    name: "MyAccount___row-fr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___row-fr"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___row-fr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___row-fr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___row-fr"
    }]
  }, {
    path: "/row-fr/Page",
    component: _08b2ebf1,
    name: "Page___row-fr"
  }, {
    path: "/row-fr/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___row-fr"
  }, {
    path: "/row-fr/Playground",
    component: _71483cc6,
    name: "Playground___row-fr"
  }, {
    path: "/row-fr/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___row-fr"
  }, {
    path: "/row-fr/Product",
    component: _4bba9d7d,
    name: "Product___row-fr"
  }, {
    path: "/row-fr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___row-fr"
  }, {
    path: "/row-fr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___row-fr"
  }, {
    path: "/row-fr/Route",
    component: _34cba952,
    name: "Route___row-fr"
  }, {
    path: "/row-fr/search",
    component: _6f8b500a,
    name: "search___row-fr"
  }, {
    path: "/row-fr/Search",
    component: _6f8b500a,
    name: "Search___row-fr"
  }, {
    path: "/row-fr/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___row-fr"
  }, {
    path: "/row-fr/traitement",
    component: _deef8500,
    name: "lavorazione-fr___row-fr"
  }, {
    path: "/row-fr/uber-uns",
    component: _632e98b3,
    name: "about-de___row-fr"
  }, {
    path: "/row-fr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___row-fr"
  }, {
    path: "/row-fr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___row-fr"
  }, {
    path: "/row-it/about",
    component: _632e98b3,
    name: "about___row-it"
  }, {
    path: "/row-it/blog",
    component: _a3ec10f8,
    name: "blog___row-it"
  }, {
    path: "/row-it/Blog",
    component: _a3ec10f8,
    name: "Blog___row-it"
  }, {
    path: "/row-it/BlogPost",
    component: _78974e44,
    name: "BlogPost___row-it"
  }, {
    path: "/row-it/cart",
    component: _544757fc,
    name: "cart___row-it"
  }, {
    path: "/row-it/Cart",
    component: _544757fc,
    name: "Cart___row-it"
  }, {
    path: "/row-it/Category",
    component: _0b2841e0,
    name: "Category___row-it"
  }, {
    path: "/row-it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___row-it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___row-it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___row-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___row-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___row-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___row-it"
    }]
  }, {
    path: "/row-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___row-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___row-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___row-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___row-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___row-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___row-it"
    }]
  }, {
    path: "/row-it/cms-preview",
    component: _35347821,
    name: "cms-preview___row-it"
  }, {
    path: "/row-it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___row-it"
  }, {
    path: "/row-it/contact",
    component: _b1da17a4,
    name: "contact-fr___row-it"
  }, {
    path: "/row-it/contact",
    component: _b1da17a4,
    name: "contact___row-it"
  }, {
    path: "/row-it/contact",
    component: _b1da17a4,
    name: "contact___row-it"
  }, {
    path: "/row-it/Contact",
    component: _b1da17a4,
    name: "Contact___row-it"
  }, {
    path: "/row-it/contacto",
    component: _b1da17a4,
    name: "contact-es___row-it"
  }, {
    path: "/row-it/contract",
    component: _c90f5248,
    name: "contract___row-it"
  }, {
    path: "/row-it/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___row-it"
  }, {
    path: "/row-it/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___row-it"
  }, {
    path: "/row-it/Home",
    component: _8f04537e,
    name: "Home___row-it"
  }, {
    path: "/row-it/kontakt",
    component: _b1da17a4,
    name: "contact-de___row-it"
  }, {
    path: "/row-it/lavorazione",
    component: _deef8500,
    name: "lavorazione___row-it"
  }, {
    path: "/row-it/listing-preview",
    component: _3dc44706,
    name: "listing-preview___row-it"
  }, {
    path: "/row-it/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___row-it"
  }, {
    path: "/row-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___row-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___row-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___row-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___row-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___row-it"
    }]
  }, {
    path: "/row-it/Page",
    component: _08b2ebf1,
    name: "Page___row-it"
  }, {
    path: "/row-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___row-it"
  }, {
    path: "/row-it/Playground",
    component: _71483cc6,
    name: "Playground___row-it"
  }, {
    path: "/row-it/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___row-it"
  }, {
    path: "/row-it/Product",
    component: _4bba9d7d,
    name: "Product___row-it"
  }, {
    path: "/row-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___row-it"
  }, {
    path: "/row-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___row-it"
  }, {
    path: "/row-it/Route",
    component: _34cba952,
    name: "Route___row-it"
  }, {
    path: "/row-it/search",
    component: _6f8b500a,
    name: "search___row-it"
  }, {
    path: "/row-it/Search",
    component: _6f8b500a,
    name: "Search___row-it"
  }, {
    path: "/row-it/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___row-it"
  }, {
    path: "/row-it/traitement",
    component: _deef8500,
    name: "lavorazione-fr___row-it"
  }, {
    path: "/row-it/uber-uns",
    component: _632e98b3,
    name: "about-de___row-it"
  }, {
    path: "/row-it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___row-it"
  }, {
    path: "/row-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___row-it"
  }, {
    path: "/sa-de/about",
    component: _632e98b3,
    name: "about___sa-de"
  }, {
    path: "/sa-de/blog",
    component: _a3ec10f8,
    name: "blog___sa-de"
  }, {
    path: "/sa-de/Blog",
    component: _a3ec10f8,
    name: "Blog___sa-de"
  }, {
    path: "/sa-de/BlogPost",
    component: _78974e44,
    name: "BlogPost___sa-de"
  }, {
    path: "/sa-de/cart",
    component: _544757fc,
    name: "cart___sa-de"
  }, {
    path: "/sa-de/Cart",
    component: _544757fc,
    name: "Cart___sa-de"
  }, {
    path: "/sa-de/Category",
    component: _0b2841e0,
    name: "Category___sa-de"
  }, {
    path: "/sa-de/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___sa-de",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___sa-de"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___sa-de"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___sa-de"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___sa-de"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___sa-de"
    }]
  }, {
    path: "/sa-de/Checkout",
    component: _48ef6e28,
    name: "Checkout___sa-de",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___sa-de"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___sa-de"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___sa-de"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___sa-de"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___sa-de"
    }]
  }, {
    path: "/sa-de/cms-preview",
    component: _35347821,
    name: "cms-preview___sa-de"
  }, {
    path: "/sa-de/CmsPreview",
    component: _35347821,
    name: "CmsPreview___sa-de"
  }, {
    path: "/sa-de/contact",
    component: _b1da17a4,
    name: "contact-fr___sa-de"
  }, {
    path: "/sa-de/contact",
    component: _b1da17a4,
    name: "contact___sa-de"
  }, {
    path: "/sa-de/contact",
    component: _b1da17a4,
    name: "contact___sa-de"
  }, {
    path: "/sa-de/Contact",
    component: _b1da17a4,
    name: "Contact___sa-de"
  }, {
    path: "/sa-de/contacto",
    component: _b1da17a4,
    name: "contact-es___sa-de"
  }, {
    path: "/sa-de/contract",
    component: _c90f5248,
    name: "contract___sa-de"
  }, {
    path: "/sa-de/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___sa-de"
  }, {
    path: "/sa-de/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___sa-de"
  }, {
    path: "/sa-de/Home",
    component: _8f04537e,
    name: "Home___sa-de"
  }, {
    path: "/sa-de/kontakt",
    component: _b1da17a4,
    name: "contact-de___sa-de"
  }, {
    path: "/sa-de/lavorazione",
    component: _deef8500,
    name: "lavorazione___sa-de"
  }, {
    path: "/sa-de/listing-preview",
    component: _3dc44706,
    name: "listing-preview___sa-de"
  }, {
    path: "/sa-de/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___sa-de"
  }, {
    path: "/sa-de/MyAccount",
    component: _10189cef,
    name: "MyAccount___sa-de",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___sa-de"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___sa-de"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___sa-de"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___sa-de"
    }]
  }, {
    path: "/sa-de/Page",
    component: _08b2ebf1,
    name: "Page___sa-de"
  }, {
    path: "/sa-de/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___sa-de"
  }, {
    path: "/sa-de/Playground",
    component: _71483cc6,
    name: "Playground___sa-de"
  }, {
    path: "/sa-de/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___sa-de"
  }, {
    path: "/sa-de/Product",
    component: _4bba9d7d,
    name: "Product___sa-de"
  }, {
    path: "/sa-de/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___sa-de"
  }, {
    path: "/sa-de/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___sa-de"
  }, {
    path: "/sa-de/Route",
    component: _34cba952,
    name: "Route___sa-de"
  }, {
    path: "/sa-de/search",
    component: _6f8b500a,
    name: "search___sa-de"
  }, {
    path: "/sa-de/Search",
    component: _6f8b500a,
    name: "Search___sa-de"
  }, {
    path: "/sa-de/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___sa-de"
  }, {
    path: "/sa-de/traitement",
    component: _deef8500,
    name: "lavorazione-fr___sa-de"
  }, {
    path: "/sa-de/uber-uns",
    component: _632e98b3,
    name: "about-de___sa-de"
  }, {
    path: "/sa-de/wishlist",
    component: _3fbd01b2,
    name: "wishlist___sa-de"
  }, {
    path: "/sa-de/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___sa-de"
  }, {
    path: "/sa-en/about",
    component: _632e98b3,
    name: "about___sa-en"
  }, {
    path: "/sa-en/blog",
    component: _a3ec10f8,
    name: "blog___sa-en"
  }, {
    path: "/sa-en/Blog",
    component: _a3ec10f8,
    name: "Blog___sa-en"
  }, {
    path: "/sa-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___sa-en"
  }, {
    path: "/sa-en/cart",
    component: _544757fc,
    name: "cart___sa-en"
  }, {
    path: "/sa-en/Cart",
    component: _544757fc,
    name: "Cart___sa-en"
  }, {
    path: "/sa-en/Category",
    component: _0b2841e0,
    name: "Category___sa-en"
  }, {
    path: "/sa-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___sa-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___sa-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___sa-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___sa-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___sa-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___sa-en"
    }]
  }, {
    path: "/sa-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___sa-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___sa-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___sa-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___sa-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___sa-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___sa-en"
    }]
  }, {
    path: "/sa-en/cms-preview",
    component: _35347821,
    name: "cms-preview___sa-en"
  }, {
    path: "/sa-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___sa-en"
  }, {
    path: "/sa-en/contact",
    component: _b1da17a4,
    name: "contact-fr___sa-en"
  }, {
    path: "/sa-en/contact",
    component: _b1da17a4,
    name: "contact___sa-en"
  }, {
    path: "/sa-en/contact",
    component: _b1da17a4,
    name: "contact___sa-en"
  }, {
    path: "/sa-en/Contact",
    component: _b1da17a4,
    name: "Contact___sa-en"
  }, {
    path: "/sa-en/contacto",
    component: _b1da17a4,
    name: "contact-es___sa-en"
  }, {
    path: "/sa-en/contract",
    component: _c90f5248,
    name: "contract___sa-en"
  }, {
    path: "/sa-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___sa-en"
  }, {
    path: "/sa-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___sa-en"
  }, {
    path: "/sa-en/Home",
    component: _8f04537e,
    name: "Home___sa-en"
  }, {
    path: "/sa-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___sa-en"
  }, {
    path: "/sa-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___sa-en"
  }, {
    path: "/sa-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___sa-en"
  }, {
    path: "/sa-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___sa-en"
  }, {
    path: "/sa-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___sa-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___sa-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___sa-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___sa-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___sa-en"
    }]
  }, {
    path: "/sa-en/Page",
    component: _08b2ebf1,
    name: "Page___sa-en"
  }, {
    path: "/sa-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___sa-en"
  }, {
    path: "/sa-en/Playground",
    component: _71483cc6,
    name: "Playground___sa-en"
  }, {
    path: "/sa-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___sa-en"
  }, {
    path: "/sa-en/Product",
    component: _4bba9d7d,
    name: "Product___sa-en"
  }, {
    path: "/sa-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___sa-en"
  }, {
    path: "/sa-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___sa-en"
  }, {
    path: "/sa-en/Route",
    component: _34cba952,
    name: "Route___sa-en"
  }, {
    path: "/sa-en/search",
    component: _6f8b500a,
    name: "search___sa-en"
  }, {
    path: "/sa-en/Search",
    component: _6f8b500a,
    name: "Search___sa-en"
  }, {
    path: "/sa-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___sa-en"
  }, {
    path: "/sa-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___sa-en"
  }, {
    path: "/sa-en/uber-uns",
    component: _632e98b3,
    name: "about-de___sa-en"
  }, {
    path: "/sa-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___sa-en"
  }, {
    path: "/sa-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___sa-en"
  }, {
    path: "/sa-es/about",
    component: _632e98b3,
    name: "about___sa-es"
  }, {
    path: "/sa-es/blog",
    component: _a3ec10f8,
    name: "blog___sa-es"
  }, {
    path: "/sa-es/Blog",
    component: _a3ec10f8,
    name: "Blog___sa-es"
  }, {
    path: "/sa-es/BlogPost",
    component: _78974e44,
    name: "BlogPost___sa-es"
  }, {
    path: "/sa-es/cart",
    component: _544757fc,
    name: "cart___sa-es"
  }, {
    path: "/sa-es/Cart",
    component: _544757fc,
    name: "Cart___sa-es"
  }, {
    path: "/sa-es/Category",
    component: _0b2841e0,
    name: "Category___sa-es"
  }, {
    path: "/sa-es/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___sa-es",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___sa-es"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___sa-es"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___sa-es"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___sa-es"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___sa-es"
    }]
  }, {
    path: "/sa-es/Checkout",
    component: _48ef6e28,
    name: "Checkout___sa-es",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___sa-es"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___sa-es"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___sa-es"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___sa-es"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___sa-es"
    }]
  }, {
    path: "/sa-es/cms-preview",
    component: _35347821,
    name: "cms-preview___sa-es"
  }, {
    path: "/sa-es/CmsPreview",
    component: _35347821,
    name: "CmsPreview___sa-es"
  }, {
    path: "/sa-es/contact",
    component: _b1da17a4,
    name: "contact-fr___sa-es"
  }, {
    path: "/sa-es/contact",
    component: _b1da17a4,
    name: "contact___sa-es"
  }, {
    path: "/sa-es/contact",
    component: _b1da17a4,
    name: "contact___sa-es"
  }, {
    path: "/sa-es/Contact",
    component: _b1da17a4,
    name: "Contact___sa-es"
  }, {
    path: "/sa-es/contacto",
    component: _b1da17a4,
    name: "contact-es___sa-es"
  }, {
    path: "/sa-es/contract",
    component: _c90f5248,
    name: "contract___sa-es"
  }, {
    path: "/sa-es/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___sa-es"
  }, {
    path: "/sa-es/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___sa-es"
  }, {
    path: "/sa-es/Home",
    component: _8f04537e,
    name: "Home___sa-es"
  }, {
    path: "/sa-es/kontakt",
    component: _b1da17a4,
    name: "contact-de___sa-es"
  }, {
    path: "/sa-es/lavorazione",
    component: _deef8500,
    name: "lavorazione___sa-es"
  }, {
    path: "/sa-es/listing-preview",
    component: _3dc44706,
    name: "listing-preview___sa-es"
  }, {
    path: "/sa-es/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___sa-es"
  }, {
    path: "/sa-es/MyAccount",
    component: _10189cef,
    name: "MyAccount___sa-es",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___sa-es"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___sa-es"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___sa-es"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___sa-es"
    }]
  }, {
    path: "/sa-es/Page",
    component: _08b2ebf1,
    name: "Page___sa-es"
  }, {
    path: "/sa-es/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___sa-es"
  }, {
    path: "/sa-es/Playground",
    component: _71483cc6,
    name: "Playground___sa-es"
  }, {
    path: "/sa-es/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___sa-es"
  }, {
    path: "/sa-es/Product",
    component: _4bba9d7d,
    name: "Product___sa-es"
  }, {
    path: "/sa-es/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___sa-es"
  }, {
    path: "/sa-es/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___sa-es"
  }, {
    path: "/sa-es/Route",
    component: _34cba952,
    name: "Route___sa-es"
  }, {
    path: "/sa-es/search",
    component: _6f8b500a,
    name: "search___sa-es"
  }, {
    path: "/sa-es/Search",
    component: _6f8b500a,
    name: "Search___sa-es"
  }, {
    path: "/sa-es/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___sa-es"
  }, {
    path: "/sa-es/traitement",
    component: _deef8500,
    name: "lavorazione-fr___sa-es"
  }, {
    path: "/sa-es/uber-uns",
    component: _632e98b3,
    name: "about-de___sa-es"
  }, {
    path: "/sa-es/wishlist",
    component: _3fbd01b2,
    name: "wishlist___sa-es"
  }, {
    path: "/sa-es/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___sa-es"
  }, {
    path: "/sa-fr/about",
    component: _632e98b3,
    name: "about___sa-fr"
  }, {
    path: "/sa-fr/blog",
    component: _a3ec10f8,
    name: "blog___sa-fr"
  }, {
    path: "/sa-fr/Blog",
    component: _a3ec10f8,
    name: "Blog___sa-fr"
  }, {
    path: "/sa-fr/BlogPost",
    component: _78974e44,
    name: "BlogPost___sa-fr"
  }, {
    path: "/sa-fr/cart",
    component: _544757fc,
    name: "cart___sa-fr"
  }, {
    path: "/sa-fr/Cart",
    component: _544757fc,
    name: "Cart___sa-fr"
  }, {
    path: "/sa-fr/Category",
    component: _0b2841e0,
    name: "Category___sa-fr"
  }, {
    path: "/sa-fr/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___sa-fr",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___sa-fr"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___sa-fr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___sa-fr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___sa-fr"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___sa-fr"
    }]
  }, {
    path: "/sa-fr/Checkout",
    component: _48ef6e28,
    name: "Checkout___sa-fr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___sa-fr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___sa-fr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___sa-fr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___sa-fr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___sa-fr"
    }]
  }, {
    path: "/sa-fr/cms-preview",
    component: _35347821,
    name: "cms-preview___sa-fr"
  }, {
    path: "/sa-fr/CmsPreview",
    component: _35347821,
    name: "CmsPreview___sa-fr"
  }, {
    path: "/sa-fr/contact",
    component: _b1da17a4,
    name: "contact-fr___sa-fr"
  }, {
    path: "/sa-fr/contact",
    component: _b1da17a4,
    name: "contact___sa-fr"
  }, {
    path: "/sa-fr/contact",
    component: _b1da17a4,
    name: "contact___sa-fr"
  }, {
    path: "/sa-fr/Contact",
    component: _b1da17a4,
    name: "Contact___sa-fr"
  }, {
    path: "/sa-fr/contacto",
    component: _b1da17a4,
    name: "contact-es___sa-fr"
  }, {
    path: "/sa-fr/contract",
    component: _c90f5248,
    name: "contract___sa-fr"
  }, {
    path: "/sa-fr/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___sa-fr"
  }, {
    path: "/sa-fr/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___sa-fr"
  }, {
    path: "/sa-fr/Home",
    component: _8f04537e,
    name: "Home___sa-fr"
  }, {
    path: "/sa-fr/kontakt",
    component: _b1da17a4,
    name: "contact-de___sa-fr"
  }, {
    path: "/sa-fr/lavorazione",
    component: _deef8500,
    name: "lavorazione___sa-fr"
  }, {
    path: "/sa-fr/listing-preview",
    component: _3dc44706,
    name: "listing-preview___sa-fr"
  }, {
    path: "/sa-fr/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___sa-fr"
  }, {
    path: "/sa-fr/MyAccount",
    component: _10189cef,
    name: "MyAccount___sa-fr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___sa-fr"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___sa-fr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___sa-fr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___sa-fr"
    }]
  }, {
    path: "/sa-fr/Page",
    component: _08b2ebf1,
    name: "Page___sa-fr"
  }, {
    path: "/sa-fr/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___sa-fr"
  }, {
    path: "/sa-fr/Playground",
    component: _71483cc6,
    name: "Playground___sa-fr"
  }, {
    path: "/sa-fr/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___sa-fr"
  }, {
    path: "/sa-fr/Product",
    component: _4bba9d7d,
    name: "Product___sa-fr"
  }, {
    path: "/sa-fr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___sa-fr"
  }, {
    path: "/sa-fr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___sa-fr"
  }, {
    path: "/sa-fr/Route",
    component: _34cba952,
    name: "Route___sa-fr"
  }, {
    path: "/sa-fr/search",
    component: _6f8b500a,
    name: "search___sa-fr"
  }, {
    path: "/sa-fr/Search",
    component: _6f8b500a,
    name: "Search___sa-fr"
  }, {
    path: "/sa-fr/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___sa-fr"
  }, {
    path: "/sa-fr/traitement",
    component: _deef8500,
    name: "lavorazione-fr___sa-fr"
  }, {
    path: "/sa-fr/uber-uns",
    component: _632e98b3,
    name: "about-de___sa-fr"
  }, {
    path: "/sa-fr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___sa-fr"
  }, {
    path: "/sa-fr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___sa-fr"
  }, {
    path: "/sa-it/about",
    component: _632e98b3,
    name: "about___sa-it"
  }, {
    path: "/sa-it/blog",
    component: _a3ec10f8,
    name: "blog___sa-it"
  }, {
    path: "/sa-it/Blog",
    component: _a3ec10f8,
    name: "Blog___sa-it"
  }, {
    path: "/sa-it/BlogPost",
    component: _78974e44,
    name: "BlogPost___sa-it"
  }, {
    path: "/sa-it/cart",
    component: _544757fc,
    name: "cart___sa-it"
  }, {
    path: "/sa-it/Cart",
    component: _544757fc,
    name: "Cart___sa-it"
  }, {
    path: "/sa-it/Category",
    component: _0b2841e0,
    name: "Category___sa-it"
  }, {
    path: "/sa-it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___sa-it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___sa-it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___sa-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___sa-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___sa-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___sa-it"
    }]
  }, {
    path: "/sa-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___sa-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___sa-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___sa-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___sa-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___sa-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___sa-it"
    }]
  }, {
    path: "/sa-it/cms-preview",
    component: _35347821,
    name: "cms-preview___sa-it"
  }, {
    path: "/sa-it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___sa-it"
  }, {
    path: "/sa-it/contact",
    component: _b1da17a4,
    name: "contact-fr___sa-it"
  }, {
    path: "/sa-it/contact",
    component: _b1da17a4,
    name: "contact___sa-it"
  }, {
    path: "/sa-it/contact",
    component: _b1da17a4,
    name: "contact___sa-it"
  }, {
    path: "/sa-it/Contact",
    component: _b1da17a4,
    name: "Contact___sa-it"
  }, {
    path: "/sa-it/contacto",
    component: _b1da17a4,
    name: "contact-es___sa-it"
  }, {
    path: "/sa-it/contract",
    component: _c90f5248,
    name: "contract___sa-it"
  }, {
    path: "/sa-it/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___sa-it"
  }, {
    path: "/sa-it/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___sa-it"
  }, {
    path: "/sa-it/Home",
    component: _8f04537e,
    name: "Home___sa-it"
  }, {
    path: "/sa-it/kontakt",
    component: _b1da17a4,
    name: "contact-de___sa-it"
  }, {
    path: "/sa-it/lavorazione",
    component: _deef8500,
    name: "lavorazione___sa-it"
  }, {
    path: "/sa-it/listing-preview",
    component: _3dc44706,
    name: "listing-preview___sa-it"
  }, {
    path: "/sa-it/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___sa-it"
  }, {
    path: "/sa-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___sa-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___sa-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___sa-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___sa-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___sa-it"
    }]
  }, {
    path: "/sa-it/Page",
    component: _08b2ebf1,
    name: "Page___sa-it"
  }, {
    path: "/sa-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___sa-it"
  }, {
    path: "/sa-it/Playground",
    component: _71483cc6,
    name: "Playground___sa-it"
  }, {
    path: "/sa-it/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___sa-it"
  }, {
    path: "/sa-it/Product",
    component: _4bba9d7d,
    name: "Product___sa-it"
  }, {
    path: "/sa-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___sa-it"
  }, {
    path: "/sa-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___sa-it"
  }, {
    path: "/sa-it/Route",
    component: _34cba952,
    name: "Route___sa-it"
  }, {
    path: "/sa-it/search",
    component: _6f8b500a,
    name: "search___sa-it"
  }, {
    path: "/sa-it/Search",
    component: _6f8b500a,
    name: "Search___sa-it"
  }, {
    path: "/sa-it/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___sa-it"
  }, {
    path: "/sa-it/traitement",
    component: _deef8500,
    name: "lavorazione-fr___sa-it"
  }, {
    path: "/sa-it/uber-uns",
    component: _632e98b3,
    name: "about-de___sa-it"
  }, {
    path: "/sa-it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___sa-it"
  }, {
    path: "/sa-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___sa-it"
  }, {
    path: "/uk-de/about",
    component: _632e98b3,
    name: "about___uk-de"
  }, {
    path: "/uk-de/blog",
    component: _a3ec10f8,
    name: "blog___uk-de"
  }, {
    path: "/uk-de/Blog",
    component: _a3ec10f8,
    name: "Blog___uk-de"
  }, {
    path: "/uk-de/BlogPost",
    component: _78974e44,
    name: "BlogPost___uk-de"
  }, {
    path: "/uk-de/cart",
    component: _544757fc,
    name: "cart___uk-de"
  }, {
    path: "/uk-de/Cart",
    component: _544757fc,
    name: "Cart___uk-de"
  }, {
    path: "/uk-de/Category",
    component: _0b2841e0,
    name: "Category___uk-de"
  }, {
    path: "/uk-de/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___uk-de",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___uk-de"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___uk-de"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___uk-de"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___uk-de"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___uk-de"
    }]
  }, {
    path: "/uk-de/Checkout",
    component: _48ef6e28,
    name: "Checkout___uk-de",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uk-de"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uk-de"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uk-de"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uk-de"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uk-de"
    }]
  }, {
    path: "/uk-de/cms-preview",
    component: _35347821,
    name: "cms-preview___uk-de"
  }, {
    path: "/uk-de/CmsPreview",
    component: _35347821,
    name: "CmsPreview___uk-de"
  }, {
    path: "/uk-de/contact",
    component: _b1da17a4,
    name: "contact-fr___uk-de"
  }, {
    path: "/uk-de/contact",
    component: _b1da17a4,
    name: "contact___uk-de"
  }, {
    path: "/uk-de/contact",
    component: _b1da17a4,
    name: "contact___uk-de"
  }, {
    path: "/uk-de/Contact",
    component: _b1da17a4,
    name: "Contact___uk-de"
  }, {
    path: "/uk-de/contacto",
    component: _b1da17a4,
    name: "contact-es___uk-de"
  }, {
    path: "/uk-de/contract",
    component: _c90f5248,
    name: "contract___uk-de"
  }, {
    path: "/uk-de/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___uk-de"
  }, {
    path: "/uk-de/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___uk-de"
  }, {
    path: "/uk-de/Home",
    component: _8f04537e,
    name: "Home___uk-de"
  }, {
    path: "/uk-de/kontakt",
    component: _b1da17a4,
    name: "contact-de___uk-de"
  }, {
    path: "/uk-de/lavorazione",
    component: _deef8500,
    name: "lavorazione___uk-de"
  }, {
    path: "/uk-de/listing-preview",
    component: _3dc44706,
    name: "listing-preview___uk-de"
  }, {
    path: "/uk-de/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___uk-de"
  }, {
    path: "/uk-de/MyAccount",
    component: _10189cef,
    name: "MyAccount___uk-de",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uk-de"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___uk-de"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uk-de"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uk-de"
    }]
  }, {
    path: "/uk-de/Page",
    component: _08b2ebf1,
    name: "Page___uk-de"
  }, {
    path: "/uk-de/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___uk-de"
  }, {
    path: "/uk-de/Playground",
    component: _71483cc6,
    name: "Playground___uk-de"
  }, {
    path: "/uk-de/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___uk-de"
  }, {
    path: "/uk-de/Product",
    component: _4bba9d7d,
    name: "Product___uk-de"
  }, {
    path: "/uk-de/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uk-de"
  }, {
    path: "/uk-de/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uk-de"
  }, {
    path: "/uk-de/Route",
    component: _34cba952,
    name: "Route___uk-de"
  }, {
    path: "/uk-de/search",
    component: _6f8b500a,
    name: "search___uk-de"
  }, {
    path: "/uk-de/Search",
    component: _6f8b500a,
    name: "Search___uk-de"
  }, {
    path: "/uk-de/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___uk-de"
  }, {
    path: "/uk-de/traitement",
    component: _deef8500,
    name: "lavorazione-fr___uk-de"
  }, {
    path: "/uk-de/uber-uns",
    component: _632e98b3,
    name: "about-de___uk-de"
  }, {
    path: "/uk-de/wishlist",
    component: _3fbd01b2,
    name: "wishlist___uk-de"
  }, {
    path: "/uk-de/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uk-de"
  }, {
    path: "/uk-en/about",
    component: _632e98b3,
    name: "about___uk-en"
  }, {
    path: "/uk-en/blog",
    component: _a3ec10f8,
    name: "blog___uk-en"
  }, {
    path: "/uk-en/Blog",
    component: _a3ec10f8,
    name: "Blog___uk-en"
  }, {
    path: "/uk-en/BlogPost",
    component: _78974e44,
    name: "BlogPost___uk-en"
  }, {
    path: "/uk-en/cart",
    component: _544757fc,
    name: "cart___uk-en"
  }, {
    path: "/uk-en/Cart",
    component: _544757fc,
    name: "Cart___uk-en"
  }, {
    path: "/uk-en/Category",
    component: _0b2841e0,
    name: "Category___uk-en"
  }, {
    path: "/uk-en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___uk-en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___uk-en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___uk-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___uk-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___uk-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___uk-en"
    }]
  }, {
    path: "/uk-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___uk-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uk-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uk-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uk-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uk-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uk-en"
    }]
  }, {
    path: "/uk-en/cms-preview",
    component: _35347821,
    name: "cms-preview___uk-en"
  }, {
    path: "/uk-en/CmsPreview",
    component: _35347821,
    name: "CmsPreview___uk-en"
  }, {
    path: "/uk-en/contact",
    component: _b1da17a4,
    name: "contact-fr___uk-en"
  }, {
    path: "/uk-en/contact",
    component: _b1da17a4,
    name: "contact___uk-en"
  }, {
    path: "/uk-en/contact",
    component: _b1da17a4,
    name: "contact___uk-en"
  }, {
    path: "/uk-en/Contact",
    component: _b1da17a4,
    name: "Contact___uk-en"
  }, {
    path: "/uk-en/contacto",
    component: _b1da17a4,
    name: "contact-es___uk-en"
  }, {
    path: "/uk-en/contract",
    component: _c90f5248,
    name: "contract___uk-en"
  }, {
    path: "/uk-en/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___uk-en"
  }, {
    path: "/uk-en/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___uk-en"
  }, {
    path: "/uk-en/Home",
    component: _8f04537e,
    name: "Home___uk-en"
  }, {
    path: "/uk-en/kontakt",
    component: _b1da17a4,
    name: "contact-de___uk-en"
  }, {
    path: "/uk-en/lavorazione",
    component: _deef8500,
    name: "lavorazione___uk-en"
  }, {
    path: "/uk-en/listing-preview",
    component: _3dc44706,
    name: "listing-preview___uk-en"
  }, {
    path: "/uk-en/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___uk-en"
  }, {
    path: "/uk-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___uk-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uk-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___uk-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uk-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uk-en"
    }]
  }, {
    path: "/uk-en/Page",
    component: _08b2ebf1,
    name: "Page___uk-en"
  }, {
    path: "/uk-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___uk-en"
  }, {
    path: "/uk-en/Playground",
    component: _71483cc6,
    name: "Playground___uk-en"
  }, {
    path: "/uk-en/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___uk-en"
  }, {
    path: "/uk-en/Product",
    component: _4bba9d7d,
    name: "Product___uk-en"
  }, {
    path: "/uk-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uk-en"
  }, {
    path: "/uk-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uk-en"
  }, {
    path: "/uk-en/Route",
    component: _34cba952,
    name: "Route___uk-en"
  }, {
    path: "/uk-en/search",
    component: _6f8b500a,
    name: "search___uk-en"
  }, {
    path: "/uk-en/Search",
    component: _6f8b500a,
    name: "Search___uk-en"
  }, {
    path: "/uk-en/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___uk-en"
  }, {
    path: "/uk-en/traitement",
    component: _deef8500,
    name: "lavorazione-fr___uk-en"
  }, {
    path: "/uk-en/uber-uns",
    component: _632e98b3,
    name: "about-de___uk-en"
  }, {
    path: "/uk-en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___uk-en"
  }, {
    path: "/uk-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uk-en"
  }, {
    path: "/uk-es/about",
    component: _632e98b3,
    name: "about___uk-es"
  }, {
    path: "/uk-es/blog",
    component: _a3ec10f8,
    name: "blog___uk-es"
  }, {
    path: "/uk-es/Blog",
    component: _a3ec10f8,
    name: "Blog___uk-es"
  }, {
    path: "/uk-es/BlogPost",
    component: _78974e44,
    name: "BlogPost___uk-es"
  }, {
    path: "/uk-es/cart",
    component: _544757fc,
    name: "cart___uk-es"
  }, {
    path: "/uk-es/Cart",
    component: _544757fc,
    name: "Cart___uk-es"
  }, {
    path: "/uk-es/Category",
    component: _0b2841e0,
    name: "Category___uk-es"
  }, {
    path: "/uk-es/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___uk-es",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___uk-es"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___uk-es"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___uk-es"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___uk-es"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___uk-es"
    }]
  }, {
    path: "/uk-es/Checkout",
    component: _48ef6e28,
    name: "Checkout___uk-es",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uk-es"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uk-es"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uk-es"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uk-es"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uk-es"
    }]
  }, {
    path: "/uk-es/cms-preview",
    component: _35347821,
    name: "cms-preview___uk-es"
  }, {
    path: "/uk-es/CmsPreview",
    component: _35347821,
    name: "CmsPreview___uk-es"
  }, {
    path: "/uk-es/contact",
    component: _b1da17a4,
    name: "contact-fr___uk-es"
  }, {
    path: "/uk-es/contact",
    component: _b1da17a4,
    name: "contact___uk-es"
  }, {
    path: "/uk-es/contact",
    component: _b1da17a4,
    name: "contact___uk-es"
  }, {
    path: "/uk-es/Contact",
    component: _b1da17a4,
    name: "Contact___uk-es"
  }, {
    path: "/uk-es/contacto",
    component: _b1da17a4,
    name: "contact-es___uk-es"
  }, {
    path: "/uk-es/contract",
    component: _c90f5248,
    name: "contract___uk-es"
  }, {
    path: "/uk-es/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___uk-es"
  }, {
    path: "/uk-es/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___uk-es"
  }, {
    path: "/uk-es/Home",
    component: _8f04537e,
    name: "Home___uk-es"
  }, {
    path: "/uk-es/kontakt",
    component: _b1da17a4,
    name: "contact-de___uk-es"
  }, {
    path: "/uk-es/lavorazione",
    component: _deef8500,
    name: "lavorazione___uk-es"
  }, {
    path: "/uk-es/listing-preview",
    component: _3dc44706,
    name: "listing-preview___uk-es"
  }, {
    path: "/uk-es/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___uk-es"
  }, {
    path: "/uk-es/MyAccount",
    component: _10189cef,
    name: "MyAccount___uk-es",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uk-es"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___uk-es"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uk-es"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uk-es"
    }]
  }, {
    path: "/uk-es/Page",
    component: _08b2ebf1,
    name: "Page___uk-es"
  }, {
    path: "/uk-es/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___uk-es"
  }, {
    path: "/uk-es/Playground",
    component: _71483cc6,
    name: "Playground___uk-es"
  }, {
    path: "/uk-es/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___uk-es"
  }, {
    path: "/uk-es/Product",
    component: _4bba9d7d,
    name: "Product___uk-es"
  }, {
    path: "/uk-es/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uk-es"
  }, {
    path: "/uk-es/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uk-es"
  }, {
    path: "/uk-es/Route",
    component: _34cba952,
    name: "Route___uk-es"
  }, {
    path: "/uk-es/search",
    component: _6f8b500a,
    name: "search___uk-es"
  }, {
    path: "/uk-es/Search",
    component: _6f8b500a,
    name: "Search___uk-es"
  }, {
    path: "/uk-es/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___uk-es"
  }, {
    path: "/uk-es/traitement",
    component: _deef8500,
    name: "lavorazione-fr___uk-es"
  }, {
    path: "/uk-es/uber-uns",
    component: _632e98b3,
    name: "about-de___uk-es"
  }, {
    path: "/uk-es/wishlist",
    component: _3fbd01b2,
    name: "wishlist___uk-es"
  }, {
    path: "/uk-es/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uk-es"
  }, {
    path: "/uk-fr/about",
    component: _632e98b3,
    name: "about___uk-fr"
  }, {
    path: "/uk-fr/blog",
    component: _a3ec10f8,
    name: "blog___uk-fr"
  }, {
    path: "/uk-fr/Blog",
    component: _a3ec10f8,
    name: "Blog___uk-fr"
  }, {
    path: "/uk-fr/BlogPost",
    component: _78974e44,
    name: "BlogPost___uk-fr"
  }, {
    path: "/uk-fr/cart",
    component: _544757fc,
    name: "cart___uk-fr"
  }, {
    path: "/uk-fr/Cart",
    component: _544757fc,
    name: "Cart___uk-fr"
  }, {
    path: "/uk-fr/Category",
    component: _0b2841e0,
    name: "Category___uk-fr"
  }, {
    path: "/uk-fr/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___uk-fr",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___uk-fr"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___uk-fr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___uk-fr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___uk-fr"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___uk-fr"
    }]
  }, {
    path: "/uk-fr/Checkout",
    component: _48ef6e28,
    name: "Checkout___uk-fr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uk-fr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uk-fr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uk-fr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uk-fr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uk-fr"
    }]
  }, {
    path: "/uk-fr/cms-preview",
    component: _35347821,
    name: "cms-preview___uk-fr"
  }, {
    path: "/uk-fr/CmsPreview",
    component: _35347821,
    name: "CmsPreview___uk-fr"
  }, {
    path: "/uk-fr/contact",
    component: _b1da17a4,
    name: "contact-fr___uk-fr"
  }, {
    path: "/uk-fr/contact",
    component: _b1da17a4,
    name: "contact___uk-fr"
  }, {
    path: "/uk-fr/contact",
    component: _b1da17a4,
    name: "contact___uk-fr"
  }, {
    path: "/uk-fr/Contact",
    component: _b1da17a4,
    name: "Contact___uk-fr"
  }, {
    path: "/uk-fr/contacto",
    component: _b1da17a4,
    name: "contact-es___uk-fr"
  }, {
    path: "/uk-fr/contract",
    component: _c90f5248,
    name: "contract___uk-fr"
  }, {
    path: "/uk-fr/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___uk-fr"
  }, {
    path: "/uk-fr/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___uk-fr"
  }, {
    path: "/uk-fr/Home",
    component: _8f04537e,
    name: "Home___uk-fr"
  }, {
    path: "/uk-fr/kontakt",
    component: _b1da17a4,
    name: "contact-de___uk-fr"
  }, {
    path: "/uk-fr/lavorazione",
    component: _deef8500,
    name: "lavorazione___uk-fr"
  }, {
    path: "/uk-fr/listing-preview",
    component: _3dc44706,
    name: "listing-preview___uk-fr"
  }, {
    path: "/uk-fr/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___uk-fr"
  }, {
    path: "/uk-fr/MyAccount",
    component: _10189cef,
    name: "MyAccount___uk-fr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uk-fr"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___uk-fr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uk-fr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uk-fr"
    }]
  }, {
    path: "/uk-fr/Page",
    component: _08b2ebf1,
    name: "Page___uk-fr"
  }, {
    path: "/uk-fr/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___uk-fr"
  }, {
    path: "/uk-fr/Playground",
    component: _71483cc6,
    name: "Playground___uk-fr"
  }, {
    path: "/uk-fr/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___uk-fr"
  }, {
    path: "/uk-fr/Product",
    component: _4bba9d7d,
    name: "Product___uk-fr"
  }, {
    path: "/uk-fr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uk-fr"
  }, {
    path: "/uk-fr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uk-fr"
  }, {
    path: "/uk-fr/Route",
    component: _34cba952,
    name: "Route___uk-fr"
  }, {
    path: "/uk-fr/search",
    component: _6f8b500a,
    name: "search___uk-fr"
  }, {
    path: "/uk-fr/Search",
    component: _6f8b500a,
    name: "Search___uk-fr"
  }, {
    path: "/uk-fr/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___uk-fr"
  }, {
    path: "/uk-fr/traitement",
    component: _deef8500,
    name: "lavorazione-fr___uk-fr"
  }, {
    path: "/uk-fr/uber-uns",
    component: _632e98b3,
    name: "about-de___uk-fr"
  }, {
    path: "/uk-fr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___uk-fr"
  }, {
    path: "/uk-fr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uk-fr"
  }, {
    path: "/uk-it/about",
    component: _632e98b3,
    name: "about___uk-it"
  }, {
    path: "/uk-it/blog",
    component: _a3ec10f8,
    name: "blog___uk-it"
  }, {
    path: "/uk-it/Blog",
    component: _a3ec10f8,
    name: "Blog___uk-it"
  }, {
    path: "/uk-it/BlogPost",
    component: _78974e44,
    name: "BlogPost___uk-it"
  }, {
    path: "/uk-it/cart",
    component: _544757fc,
    name: "cart___uk-it"
  }, {
    path: "/uk-it/Cart",
    component: _544757fc,
    name: "Cart___uk-it"
  }, {
    path: "/uk-it/Category",
    component: _0b2841e0,
    name: "Category___uk-it"
  }, {
    path: "/uk-it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___uk-it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___uk-it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___uk-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___uk-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___uk-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___uk-it"
    }]
  }, {
    path: "/uk-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___uk-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uk-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uk-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uk-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uk-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uk-it"
    }]
  }, {
    path: "/uk-it/cms-preview",
    component: _35347821,
    name: "cms-preview___uk-it"
  }, {
    path: "/uk-it/CmsPreview",
    component: _35347821,
    name: "CmsPreview___uk-it"
  }, {
    path: "/uk-it/contact",
    component: _b1da17a4,
    name: "contact-fr___uk-it"
  }, {
    path: "/uk-it/contact",
    component: _b1da17a4,
    name: "contact___uk-it"
  }, {
    path: "/uk-it/contact",
    component: _b1da17a4,
    name: "contact___uk-it"
  }, {
    path: "/uk-it/Contact",
    component: _b1da17a4,
    name: "Contact___uk-it"
  }, {
    path: "/uk-it/contacto",
    component: _b1da17a4,
    name: "contact-es___uk-it"
  }, {
    path: "/uk-it/contract",
    component: _c90f5248,
    name: "contract___uk-it"
  }, {
    path: "/uk-it/craftsmanship",
    component: _deef8500,
    name: "lavorazione-en___uk-it"
  }, {
    path: "/uk-it/handwerkskunst",
    component: _deef8500,
    name: "lavorazione-de___uk-it"
  }, {
    path: "/uk-it/Home",
    component: _8f04537e,
    name: "Home___uk-it"
  }, {
    path: "/uk-it/kontakt",
    component: _b1da17a4,
    name: "contact-de___uk-it"
  }, {
    path: "/uk-it/lavorazione",
    component: _deef8500,
    name: "lavorazione___uk-it"
  }, {
    path: "/uk-it/listing-preview",
    component: _3dc44706,
    name: "listing-preview___uk-it"
  }, {
    path: "/uk-it/ListingPreview",
    component: _3dc44706,
    name: "ListingPreview___uk-it"
  }, {
    path: "/uk-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___uk-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uk-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___uk-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uk-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uk-it"
    }]
  }, {
    path: "/uk-it/Page",
    component: _08b2ebf1,
    name: "Page___uk-it"
  }, {
    path: "/uk-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___uk-it"
  }, {
    path: "/uk-it/Playground",
    component: _71483cc6,
    name: "Playground___uk-it"
  }, {
    path: "/uk-it/procesamiento",
    component: _deef8500,
    name: "lavorazione-es___uk-it"
  }, {
    path: "/uk-it/Product",
    component: _4bba9d7d,
    name: "Product___uk-it"
  }, {
    path: "/uk-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uk-it"
  }, {
    path: "/uk-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uk-it"
  }, {
    path: "/uk-it/Route",
    component: _34cba952,
    name: "Route___uk-it"
  }, {
    path: "/uk-it/search",
    component: _6f8b500a,
    name: "search___uk-it"
  }, {
    path: "/uk-it/Search",
    component: _6f8b500a,
    name: "Search___uk-it"
  }, {
    path: "/uk-it/sobre-nosotros",
    component: _632e98b3,
    name: "about-es___uk-it"
  }, {
    path: "/uk-it/traitement",
    component: _deef8500,
    name: "lavorazione-fr___uk-it"
  }, {
    path: "/uk-it/uber-uns",
    component: _632e98b3,
    name: "about-de___uk-it"
  }, {
    path: "/uk-it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___uk-it"
  }, {
    path: "/uk-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uk-it"
  }, {
    path: "/ae-de/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ae-de"
  }, {
    path: "/ae-de/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ae-de"
  }, {
    path: "/ae-de/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ae-de"
  }, {
    path: "/ae-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ae-en"
  }, {
    path: "/ae-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ae-en"
  }, {
    path: "/ae-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ae-en"
  }, {
    path: "/ae-es/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ae-es"
  }, {
    path: "/ae-es/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ae-es"
  }, {
    path: "/ae-es/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ae-es"
  }, {
    path: "/ae-fr/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ae-fr"
  }, {
    path: "/ae-fr/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ae-fr"
  }, {
    path: "/ae-fr/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ae-fr"
  }, {
    path: "/ae-it/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ae-it"
  }, {
    path: "/ae-it/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ae-it"
  }, {
    path: "/ae-it/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ae-it"
  }, {
    path: "/ch-de/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ch-de"
  }, {
    path: "/ch-de/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ch-de"
  }, {
    path: "/ch-de/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ch-de"
  }, {
    path: "/ch-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ch-en"
  }, {
    path: "/ch-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ch-en"
  }, {
    path: "/ch-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ch-en"
  }, {
    path: "/ch-es/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ch-es"
  }, {
    path: "/ch-es/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ch-es"
  }, {
    path: "/ch-es/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ch-es"
  }, {
    path: "/ch-fr/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ch-fr"
  }, {
    path: "/ch-fr/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ch-fr"
  }, {
    path: "/ch-fr/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ch-fr"
  }, {
    path: "/ch-it/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___ch-it"
  }, {
    path: "/ch-it/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___ch-it"
  }, {
    path: "/ch-it/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___ch-it"
  }, {
    path: "/eu-de/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___eu-de"
  }, {
    path: "/eu-de/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___eu-de"
  }, {
    path: "/eu-de/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___eu-de"
  }, {
    path: "/eu-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___eu-en"
  }, {
    path: "/eu-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___eu-en"
  }, {
    path: "/eu-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___eu-en"
  }, {
    path: "/eu-es/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___eu-es"
  }, {
    path: "/eu-es/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___eu-es"
  }, {
    path: "/eu-es/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___eu-es"
  }, {
    path: "/eu-fr/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___eu-fr"
  }, {
    path: "/eu-fr/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___eu-fr"
  }, {
    path: "/eu-fr/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___eu-fr"
  }, {
    path: "/eu-it/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___eu-it"
  }, {
    path: "/eu-it/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___eu-it"
  }, {
    path: "/eu-it/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___eu-it"
  }, {
    path: "/fe-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___fe-en"
  }, {
    path: "/fe-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___fe-en"
  }, {
    path: "/fe-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___fe-en"
  }, {
    path: "/it-it/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___it-it"
  }, {
    path: "/it-it/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___it-it"
  }, {
    path: "/it-it/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___it-it"
  }, {
    path: "/jp-de/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___jp-de"
  }, {
    path: "/jp-de/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___jp-de"
  }, {
    path: "/jp-de/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___jp-de"
  }, {
    path: "/jp-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___jp-en"
  }, {
    path: "/jp-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___jp-en"
  }, {
    path: "/jp-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___jp-en"
  }, {
    path: "/jp-es/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___jp-es"
  }, {
    path: "/jp-es/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___jp-es"
  }, {
    path: "/jp-es/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___jp-es"
  }, {
    path: "/jp-fr/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___jp-fr"
  }, {
    path: "/jp-fr/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___jp-fr"
  }, {
    path: "/jp-fr/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___jp-fr"
  }, {
    path: "/jp-it/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___jp-it"
  }, {
    path: "/jp-it/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___jp-it"
  }, {
    path: "/jp-it/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___jp-it"
  }, {
    path: "/me-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___me-en"
  }, {
    path: "/me-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___me-en"
  }, {
    path: "/me-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___me-en"
  }, {
    path: "/row-de/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___row-de"
  }, {
    path: "/row-de/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___row-de"
  }, {
    path: "/row-de/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___row-de"
  }, {
    path: "/row-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___row-en"
  }, {
    path: "/row-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___row-en"
  }, {
    path: "/row-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___row-en"
  }, {
    path: "/row-es/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___row-es"
  }, {
    path: "/row-es/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___row-es"
  }, {
    path: "/row-es/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___row-es"
  }, {
    path: "/row-fr/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___row-fr"
  }, {
    path: "/row-fr/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___row-fr"
  }, {
    path: "/row-fr/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___row-fr"
  }, {
    path: "/row-it/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___row-it"
  }, {
    path: "/row-it/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___row-it"
  }, {
    path: "/row-it/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___row-it"
  }, {
    path: "/sa-de/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___sa-de"
  }, {
    path: "/sa-de/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___sa-de"
  }, {
    path: "/sa-de/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___sa-de"
  }, {
    path: "/sa-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___sa-en"
  }, {
    path: "/sa-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___sa-en"
  }, {
    path: "/sa-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___sa-en"
  }, {
    path: "/sa-es/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___sa-es"
  }, {
    path: "/sa-es/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___sa-es"
  }, {
    path: "/sa-es/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___sa-es"
  }, {
    path: "/sa-fr/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___sa-fr"
  }, {
    path: "/sa-fr/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___sa-fr"
  }, {
    path: "/sa-fr/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___sa-fr"
  }, {
    path: "/sa-it/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___sa-it"
  }, {
    path: "/sa-it/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___sa-it"
  }, {
    path: "/sa-it/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___sa-it"
  }, {
    path: "/uk-de/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___uk-de"
  }, {
    path: "/uk-de/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___uk-de"
  }, {
    path: "/uk-de/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___uk-de"
  }, {
    path: "/uk-en/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___uk-en"
  }, {
    path: "/uk-en/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___uk-en"
  }, {
    path: "/uk-en/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___uk-en"
  }, {
    path: "/uk-es/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___uk-es"
  }, {
    path: "/uk-es/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___uk-es"
  }, {
    path: "/uk-es/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___uk-es"
  }, {
    path: "/uk-fr/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___uk-fr"
  }, {
    path: "/uk-fr/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___uk-fr"
  }, {
    path: "/uk-fr/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___uk-fr"
  }, {
    path: "/uk-it/Editorial/About",
    component: _632e98b3,
    name: "Editorial-About___uk-it"
  }, {
    path: "/uk-it/Editorial/Contract",
    component: _c90f5248,
    name: "Editorial-Contract___uk-it"
  }, {
    path: "/uk-it/Editorial/Lavorazione",
    component: _deef8500,
    name: "Editorial-Lavorazione___uk-it"
  }, {
    path: "/ae-de/blog/:slug",
    component: _78974e44,
    name: "blog-post___ae-de"
  }, {
    path: "/ae-de/c/:categoryId",
    component: _0b2841e0,
    name: "category___ae-de"
  }, {
    path: "/ae-de/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ae-de"
  }, {
    path: "/ae-de/p/:productId",
    component: _4bba9d7d,
    name: "product___ae-de"
  }, {
    path: "/ae-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___ae-en"
  }, {
    path: "/ae-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___ae-en"
  }, {
    path: "/ae-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ae-en"
  }, {
    path: "/ae-en/p/:productId",
    component: _4bba9d7d,
    name: "product___ae-en"
  }, {
    path: "/ae-es/blog/:slug",
    component: _78974e44,
    name: "blog-post___ae-es"
  }, {
    path: "/ae-es/c/:categoryId",
    component: _0b2841e0,
    name: "category___ae-es"
  }, {
    path: "/ae-es/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ae-es"
  }, {
    path: "/ae-es/p/:productId",
    component: _4bba9d7d,
    name: "product___ae-es"
  }, {
    path: "/ae-fr/blog/:slug",
    component: _78974e44,
    name: "blog-post___ae-fr"
  }, {
    path: "/ae-fr/c/:categoryId",
    component: _0b2841e0,
    name: "category___ae-fr"
  }, {
    path: "/ae-fr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ae-fr"
  }, {
    path: "/ae-fr/p/:productId",
    component: _4bba9d7d,
    name: "product___ae-fr"
  }, {
    path: "/ae-it/blog/:slug",
    component: _78974e44,
    name: "blog-post___ae-it"
  }, {
    path: "/ae-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___ae-it"
  }, {
    path: "/ae-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ae-it"
  }, {
    path: "/ae-it/p/:productId",
    component: _4bba9d7d,
    name: "product___ae-it"
  }, {
    path: "/ch-de/blog/:slug",
    component: _78974e44,
    name: "blog-post___ch-de"
  }, {
    path: "/ch-de/c/:categoryId",
    component: _0b2841e0,
    name: "category___ch-de"
  }, {
    path: "/ch-de/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ch-de"
  }, {
    path: "/ch-de/p/:productId",
    component: _4bba9d7d,
    name: "product___ch-de"
  }, {
    path: "/ch-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___ch-en"
  }, {
    path: "/ch-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___ch-en"
  }, {
    path: "/ch-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ch-en"
  }, {
    path: "/ch-en/p/:productId",
    component: _4bba9d7d,
    name: "product___ch-en"
  }, {
    path: "/ch-es/blog/:slug",
    component: _78974e44,
    name: "blog-post___ch-es"
  }, {
    path: "/ch-es/c/:categoryId",
    component: _0b2841e0,
    name: "category___ch-es"
  }, {
    path: "/ch-es/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ch-es"
  }, {
    path: "/ch-es/p/:productId",
    component: _4bba9d7d,
    name: "product___ch-es"
  }, {
    path: "/ch-fr/blog/:slug",
    component: _78974e44,
    name: "blog-post___ch-fr"
  }, {
    path: "/ch-fr/c/:categoryId",
    component: _0b2841e0,
    name: "category___ch-fr"
  }, {
    path: "/ch-fr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ch-fr"
  }, {
    path: "/ch-fr/p/:productId",
    component: _4bba9d7d,
    name: "product___ch-fr"
  }, {
    path: "/ch-it/blog/:slug",
    component: _78974e44,
    name: "blog-post___ch-it"
  }, {
    path: "/ch-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___ch-it"
  }, {
    path: "/ch-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ch-it"
  }, {
    path: "/ch-it/p/:productId",
    component: _4bba9d7d,
    name: "product___ch-it"
  }, {
    path: "/eu-de/blog/:slug",
    component: _78974e44,
    name: "blog-post___eu-de"
  }, {
    path: "/eu-de/c/:categoryId",
    component: _0b2841e0,
    name: "category___eu-de"
  }, {
    path: "/eu-de/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eu-de"
  }, {
    path: "/eu-de/p/:productId",
    component: _4bba9d7d,
    name: "product___eu-de"
  }, {
    path: "/eu-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___eu-en"
  }, {
    path: "/eu-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___eu-en"
  }, {
    path: "/eu-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eu-en"
  }, {
    path: "/eu-en/p/:productId",
    component: _4bba9d7d,
    name: "product___eu-en"
  }, {
    path: "/eu-es/blog/:slug",
    component: _78974e44,
    name: "blog-post___eu-es"
  }, {
    path: "/eu-es/c/:categoryId",
    component: _0b2841e0,
    name: "category___eu-es"
  }, {
    path: "/eu-es/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eu-es"
  }, {
    path: "/eu-es/p/:productId",
    component: _4bba9d7d,
    name: "product___eu-es"
  }, {
    path: "/eu-fr/blog/:slug",
    component: _78974e44,
    name: "blog-post___eu-fr"
  }, {
    path: "/eu-fr/c/:categoryId",
    component: _0b2841e0,
    name: "category___eu-fr"
  }, {
    path: "/eu-fr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eu-fr"
  }, {
    path: "/eu-fr/p/:productId",
    component: _4bba9d7d,
    name: "product___eu-fr"
  }, {
    path: "/eu-it/blog/:slug",
    component: _78974e44,
    name: "blog-post___eu-it"
  }, {
    path: "/eu-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___eu-it"
  }, {
    path: "/eu-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eu-it"
  }, {
    path: "/eu-it/p/:productId",
    component: _4bba9d7d,
    name: "product___eu-it"
  }, {
    path: "/fe-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___fe-en"
  }, {
    path: "/fe-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___fe-en"
  }, {
    path: "/fe-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___fe-en"
  }, {
    path: "/fe-en/p/:productId",
    component: _4bba9d7d,
    name: "product___fe-en"
  }, {
    path: "/it-it/blog/:slug",
    component: _78974e44,
    name: "blog-post___it-it"
  }, {
    path: "/it-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___it-it"
  }, {
    path: "/it-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___it-it"
  }, {
    path: "/it-it/p/:productId",
    component: _4bba9d7d,
    name: "product___it-it"
  }, {
    path: "/jp-de/blog/:slug",
    component: _78974e44,
    name: "blog-post___jp-de"
  }, {
    path: "/jp-de/c/:categoryId",
    component: _0b2841e0,
    name: "category___jp-de"
  }, {
    path: "/jp-de/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___jp-de"
  }, {
    path: "/jp-de/p/:productId",
    component: _4bba9d7d,
    name: "product___jp-de"
  }, {
    path: "/jp-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___jp-en"
  }, {
    path: "/jp-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___jp-en"
  }, {
    path: "/jp-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___jp-en"
  }, {
    path: "/jp-en/p/:productId",
    component: _4bba9d7d,
    name: "product___jp-en"
  }, {
    path: "/jp-es/blog/:slug",
    component: _78974e44,
    name: "blog-post___jp-es"
  }, {
    path: "/jp-es/c/:categoryId",
    component: _0b2841e0,
    name: "category___jp-es"
  }, {
    path: "/jp-es/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___jp-es"
  }, {
    path: "/jp-es/p/:productId",
    component: _4bba9d7d,
    name: "product___jp-es"
  }, {
    path: "/jp-fr/blog/:slug",
    component: _78974e44,
    name: "blog-post___jp-fr"
  }, {
    path: "/jp-fr/c/:categoryId",
    component: _0b2841e0,
    name: "category___jp-fr"
  }, {
    path: "/jp-fr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___jp-fr"
  }, {
    path: "/jp-fr/p/:productId",
    component: _4bba9d7d,
    name: "product___jp-fr"
  }, {
    path: "/jp-it/blog/:slug",
    component: _78974e44,
    name: "blog-post___jp-it"
  }, {
    path: "/jp-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___jp-it"
  }, {
    path: "/jp-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___jp-it"
  }, {
    path: "/jp-it/p/:productId",
    component: _4bba9d7d,
    name: "product___jp-it"
  }, {
    path: "/me-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___me-en"
  }, {
    path: "/me-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___me-en"
  }, {
    path: "/me-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___me-en"
  }, {
    path: "/me-en/p/:productId",
    component: _4bba9d7d,
    name: "product___me-en"
  }, {
    path: "/row-de/blog/:slug",
    component: _78974e44,
    name: "blog-post___row-de"
  }, {
    path: "/row-de/c/:categoryId",
    component: _0b2841e0,
    name: "category___row-de"
  }, {
    path: "/row-de/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___row-de"
  }, {
    path: "/row-de/p/:productId",
    component: _4bba9d7d,
    name: "product___row-de"
  }, {
    path: "/row-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___row-en"
  }, {
    path: "/row-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___row-en"
  }, {
    path: "/row-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___row-en"
  }, {
    path: "/row-en/p/:productId",
    component: _4bba9d7d,
    name: "product___row-en"
  }, {
    path: "/row-es/blog/:slug",
    component: _78974e44,
    name: "blog-post___row-es"
  }, {
    path: "/row-es/c/:categoryId",
    component: _0b2841e0,
    name: "category___row-es"
  }, {
    path: "/row-es/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___row-es"
  }, {
    path: "/row-es/p/:productId",
    component: _4bba9d7d,
    name: "product___row-es"
  }, {
    path: "/row-fr/blog/:slug",
    component: _78974e44,
    name: "blog-post___row-fr"
  }, {
    path: "/row-fr/c/:categoryId",
    component: _0b2841e0,
    name: "category___row-fr"
  }, {
    path: "/row-fr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___row-fr"
  }, {
    path: "/row-fr/p/:productId",
    component: _4bba9d7d,
    name: "product___row-fr"
  }, {
    path: "/row-it/blog/:slug",
    component: _78974e44,
    name: "blog-post___row-it"
  }, {
    path: "/row-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___row-it"
  }, {
    path: "/row-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___row-it"
  }, {
    path: "/row-it/p/:productId",
    component: _4bba9d7d,
    name: "product___row-it"
  }, {
    path: "/sa-de/blog/:slug",
    component: _78974e44,
    name: "blog-post___sa-de"
  }, {
    path: "/sa-de/c/:categoryId",
    component: _0b2841e0,
    name: "category___sa-de"
  }, {
    path: "/sa-de/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___sa-de"
  }, {
    path: "/sa-de/p/:productId",
    component: _4bba9d7d,
    name: "product___sa-de"
  }, {
    path: "/sa-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___sa-en"
  }, {
    path: "/sa-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___sa-en"
  }, {
    path: "/sa-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___sa-en"
  }, {
    path: "/sa-en/p/:productId",
    component: _4bba9d7d,
    name: "product___sa-en"
  }, {
    path: "/sa-es/blog/:slug",
    component: _78974e44,
    name: "blog-post___sa-es"
  }, {
    path: "/sa-es/c/:categoryId",
    component: _0b2841e0,
    name: "category___sa-es"
  }, {
    path: "/sa-es/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___sa-es"
  }, {
    path: "/sa-es/p/:productId",
    component: _4bba9d7d,
    name: "product___sa-es"
  }, {
    path: "/sa-fr/blog/:slug",
    component: _78974e44,
    name: "blog-post___sa-fr"
  }, {
    path: "/sa-fr/c/:categoryId",
    component: _0b2841e0,
    name: "category___sa-fr"
  }, {
    path: "/sa-fr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___sa-fr"
  }, {
    path: "/sa-fr/p/:productId",
    component: _4bba9d7d,
    name: "product___sa-fr"
  }, {
    path: "/sa-it/blog/:slug",
    component: _78974e44,
    name: "blog-post___sa-it"
  }, {
    path: "/sa-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___sa-it"
  }, {
    path: "/sa-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___sa-it"
  }, {
    path: "/sa-it/p/:productId",
    component: _4bba9d7d,
    name: "product___sa-it"
  }, {
    path: "/uk-de/blog/:slug",
    component: _78974e44,
    name: "blog-post___uk-de"
  }, {
    path: "/uk-de/c/:categoryId",
    component: _0b2841e0,
    name: "category___uk-de"
  }, {
    path: "/uk-de/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uk-de"
  }, {
    path: "/uk-de/p/:productId",
    component: _4bba9d7d,
    name: "product___uk-de"
  }, {
    path: "/uk-en/blog/:slug",
    component: _78974e44,
    name: "blog-post___uk-en"
  }, {
    path: "/uk-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___uk-en"
  }, {
    path: "/uk-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uk-en"
  }, {
    path: "/uk-en/p/:productId",
    component: _4bba9d7d,
    name: "product___uk-en"
  }, {
    path: "/uk-es/blog/:slug",
    component: _78974e44,
    name: "blog-post___uk-es"
  }, {
    path: "/uk-es/c/:categoryId",
    component: _0b2841e0,
    name: "category___uk-es"
  }, {
    path: "/uk-es/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uk-es"
  }, {
    path: "/uk-es/p/:productId",
    component: _4bba9d7d,
    name: "product___uk-es"
  }, {
    path: "/uk-fr/blog/:slug",
    component: _78974e44,
    name: "blog-post___uk-fr"
  }, {
    path: "/uk-fr/c/:categoryId",
    component: _0b2841e0,
    name: "category___uk-fr"
  }, {
    path: "/uk-fr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uk-fr"
  }, {
    path: "/uk-fr/p/:productId",
    component: _4bba9d7d,
    name: "product___uk-fr"
  }, {
    path: "/uk-it/blog/:slug",
    component: _78974e44,
    name: "blog-post___uk-it"
  }, {
    path: "/uk-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___uk-it"
  }, {
    path: "/uk-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uk-it"
  }, {
    path: "/uk-it/p/:productId",
    component: _4bba9d7d,
    name: "product___uk-it"
  }, {
    path: "/ae-de/:path+",
    component: _34cba952,
    name: "route-resolver___ae-de"
  }, {
    path: "/ae-en/:path+",
    component: _34cba952,
    name: "route-resolver___ae-en"
  }, {
    path: "/ae-es/:path+",
    component: _34cba952,
    name: "route-resolver___ae-es"
  }, {
    path: "/ae-fr/:path+",
    component: _34cba952,
    name: "route-resolver___ae-fr"
  }, {
    path: "/ae-it/:path+",
    component: _34cba952,
    name: "route-resolver___ae-it"
  }, {
    path: "/ch-de/:path+",
    component: _34cba952,
    name: "route-resolver___ch-de"
  }, {
    path: "/ch-en/:path+",
    component: _34cba952,
    name: "route-resolver___ch-en"
  }, {
    path: "/ch-es/:path+",
    component: _34cba952,
    name: "route-resolver___ch-es"
  }, {
    path: "/ch-fr/:path+",
    component: _34cba952,
    name: "route-resolver___ch-fr"
  }, {
    path: "/ch-it/:path+",
    component: _34cba952,
    name: "route-resolver___ch-it"
  }, {
    path: "/eu-de/:path+",
    component: _34cba952,
    name: "route-resolver___eu-de"
  }, {
    path: "/eu-en/:path+",
    component: _34cba952,
    name: "route-resolver___eu-en"
  }, {
    path: "/eu-es/:path+",
    component: _34cba952,
    name: "route-resolver___eu-es"
  }, {
    path: "/eu-fr/:path+",
    component: _34cba952,
    name: "route-resolver___eu-fr"
  }, {
    path: "/eu-it/:path+",
    component: _34cba952,
    name: "route-resolver___eu-it"
  }, {
    path: "/fe-en/:path+",
    component: _34cba952,
    name: "route-resolver___fe-en"
  }, {
    path: "/it-it/:path+",
    component: _34cba952,
    name: "route-resolver___it-it"
  }, {
    path: "/jp-de/:path+",
    component: _34cba952,
    name: "route-resolver___jp-de"
  }, {
    path: "/jp-en/:path+",
    component: _34cba952,
    name: "route-resolver___jp-en"
  }, {
    path: "/jp-es/:path+",
    component: _34cba952,
    name: "route-resolver___jp-es"
  }, {
    path: "/jp-fr/:path+",
    component: _34cba952,
    name: "route-resolver___jp-fr"
  }, {
    path: "/jp-it/:path+",
    component: _34cba952,
    name: "route-resolver___jp-it"
  }, {
    path: "/me-en/:path+",
    component: _34cba952,
    name: "route-resolver___me-en"
  }, {
    path: "/row-de/:path+",
    component: _34cba952,
    name: "route-resolver___row-de"
  }, {
    path: "/row-en/:path+",
    component: _34cba952,
    name: "route-resolver___row-en"
  }, {
    path: "/row-es/:path+",
    component: _34cba952,
    name: "route-resolver___row-es"
  }, {
    path: "/row-fr/:path+",
    component: _34cba952,
    name: "route-resolver___row-fr"
  }, {
    path: "/row-it/:path+",
    component: _34cba952,
    name: "route-resolver___row-it"
  }, {
    path: "/sa-de/:path+",
    component: _34cba952,
    name: "route-resolver___sa-de"
  }, {
    path: "/sa-en/:path+",
    component: _34cba952,
    name: "route-resolver___sa-en"
  }, {
    path: "/sa-es/:path+",
    component: _34cba952,
    name: "route-resolver___sa-es"
  }, {
    path: "/sa-fr/:path+",
    component: _34cba952,
    name: "route-resolver___sa-fr"
  }, {
    path: "/sa-it/:path+",
    component: _34cba952,
    name: "route-resolver___sa-it"
  }, {
    path: "/uk-de/:path+",
    component: _34cba952,
    name: "route-resolver___uk-de"
  }, {
    path: "/uk-en/:path+",
    component: _34cba952,
    name: "route-resolver___uk-en"
  }, {
    path: "/uk-es/:path+",
    component: _34cba952,
    name: "route-resolver___uk-es"
  }, {
    path: "/uk-fr/:path+",
    component: _34cba952,
    name: "route-resolver___uk-fr"
  }, {
    path: "/uk-it/:path+",
    component: _34cba952,
    name: "route-resolver___uk-it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
